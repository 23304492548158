import { API_DOMAIN, SHORCUTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';
import { z } from 'zod';

const userTypeValues = Object.freeze([
	'admin',
	'panel',
	'parkingAdmin',
	'parkingCashier',
	'parkingGate',
	'barAdmin',
	'barPanel',
	'barCashier',
	'user',
] as const satisfies Readonly<UserType[]>);

const zUserType = z.enum(userTypeValues);

export const zShorcut = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	order: z.number(),
	description: z.optional(z.string()),
	textBottom: z.optional(z.string()),
	backgroundColor: z.optional(z.string()),
	link: z.string(),
	userType: zUserType,
});

export type FetchShorcutsSearchProps = {
	search?: string | null;
};

export type FetchShorcutsProps = FetchShorcutsSearchProps & PaginationProps;

const zAppCommentsArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<PopulatedShorcut[]>((data) => {
		if (Array.isArray(data) && data.length > 0) {
			const parsed = zShorcut.safeParse(data[0]);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export async function fetchPopulatedShorcuts(props: FetchShorcutsProps): Promise<PaginateSource<PopulatedShorcut>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'shorcut']: props.order || 'asc',
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${SHORCUTS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zAppCommentsArrayResponse.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchPopulatedMeShorcuts() {
	try {
		const response = await api.get(`${API_DOMAIN}${SHORCUTS_URL}/me`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zShorcut).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createShorcut(shorcut: PopulatedShorcut) {
	const response = await api.post(`${API_DOMAIN}${SHORCUTS_URL}`, shorcut);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zShorcut.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function updateShorcut(shorcut: PopulatedShorcut) {
	const response = await api.patch(`${API_DOMAIN}${SHORCUTS_URL}/${shorcut._id}`, shorcut);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zShorcut.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteShorcut(shorcut: PopulatedShorcut): Promise<boolean | null> {
	try {
		const response = await api.delete(`${API_DOMAIN}${SHORCUTS_URL}/${shorcut._id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response?.data?._id === shorcut._id;
	} catch (error) {
		return null;
	}
}
