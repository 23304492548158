import { API_DOMAIN, PROFILE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchProfile(): Promise<Profile | null | { error: true }> {
	try {
		const response = await api.get<Profile | null>(`${API_DOMAIN}${PROFILE_URL}/`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { error: true };
	}
}

export async function updateProfile(profile: Profile): Promise<Profile> {
	const url = `${API_DOMAIN}${PROFILE_URL}/`;

	const response = await api.patch<Profile>(url, profile);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || profile;
}

export async function getCardHolderId(username: string): Promise<string> {
	const url = `${API_DOMAIN}${PROFILE_URL}/${username}/cardHolderId`;

	const response = await api.get<{ cardHolderId: string }>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (typeof response.data.cardHolderId !== 'string') {
		throw new Error('cardHolderId not present in response');
	}

	return response.data.cardHolderId;
}

export async function updateCardHolderId(username: string, cardHolderId: string): Promise<boolean> {
	const url = `${API_DOMAIN}${PROFILE_URL}/${username}/cardHolderId`;

	const response = await api.patch<Profile>(url, { cardHolderId });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return true;
}

export async function assignSection(username: string, sectionId: string): Promise<Profile> {
	const url = `${API_DOMAIN}${PROFILE_URL}/${username}/assign-section`;

	const response = await api.patch<Profile>(url, { sectionId });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function removeSection(username: string): Promise<Profile> {
	const response = await api.delete<Profile>(`${API_DOMAIN}${PROFILE_URL}/${username}/remove-section`);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function fetchUserSection(username: string): Promise<ProductSection> {
	const response = await api.get<ProductSection>(`${API_DOMAIN}${PROFILE_URL}/${username}/section`);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}
