import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { useState } from 'react';
// import QRCode from 'react-qr-code';
import QR, { QRHeader } from './QR';
import { Link } from 'react-router-dom';

interface Props {
	access: Access;
	seeActions: boolean;
	logoMute: string;
	sponsor: string;
	onChangeStatus: (id?: string) => void;
}
const ZebraAccess = ({ access, seeActions, logoMute, onChangeStatus, sponsor }: Props) => {
	const [hover, setHover] = useState(false);

	// const isMultiple = access.qrType === 'multiple';

	return (
		<Grid lg={3} md={10} sx={{ position: 'relative' }} item alignContent={'center'} justifyContent={'center'}>
			<div
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				id={access._id}
				className="zebraTicket"
				style={{
					width: '50mm',
					height: '3.145in',
					maxWidth: '50mm',
					backgroundColor: 'none',
					border: '3px solid black',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignContent: 'center',
					pageBreakAfter: 'always',
				}}
			>
				{hover && seeActions && (
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: 2,
							alignItems: 'center',
							transition: 'opacity 0.3s ease-in-out',
						}}
					>
						<ButtonGroup orientation="vertical" aria-label="Vertical button group">
							{access.qrType === 'multiple' && (
								<Button
									component={Link}
									to={`/generate?access=${access.code}`}
									size="small"
									variant="contained"
									sx={{ color: 'white' }}
									startIcon={<EditIcon fontSize="large" />}
									fullWidth
								>
									Editar
								</Button>
							)}
							<Button
								id="changeAccessStatus"
								onClick={() => onChangeStatus(access._id)}
								size="small"
								variant="contained"
								sx={{ color: 'white' }}
								startIcon={access.isActive ? <BlockIcon fontSize="large" /> : <DoneIcon fontSize="large" />}
								color={access.isActive ? 'warning' : 'success'}
								fullWidth
							>
								{access.isActive ? 'Inhabilitar' : 'Habilitar'}
							</Button>
						</ButtonGroup>
					</Box>
				)}
				<QRHeader
					logo={logoMute}
					sponsor={sponsor}
					description={typeof access.type !== 'string' ? access.type?.description : ''}
					id={access.tent}
				/>
				<QR
					value={access.code}
					validDate={
						access.qrType === 'multiple'
							? `${parseDate(access.validDate.from)} a ${parseDate(access.validDate.to)}`
							: `${parseDate(access.validDate.from)}`
					}
					size={152}
				/>
			</div>
		</Grid>
	);
};

export default ZebraAccess;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	}
	return '-';
}

const months: { [key: number]: string } = {
	0: 'ene',
	1: 'feb',
	2: 'mar',
	3: 'abr',
	4: 'may',
	5: 'jun',
	6: 'jul',
	7: 'ago',
	8: 'sep',
	9: 'oct',
	10: 'nov',
	11: 'dic',
};
