import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
	logo: string;
	sponsor?: string;
	type?: string;
	id?: string;
	description?: string;
};

function QRHeader({ logo, sponsor, id, description }: Props) {
	return (
		<Box
			sx={{
				width: '100%',
				height: '0.35in',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignContent: 'center',
				padding: '0.02in',
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<img
					style={{ height: '100%', width: 'auto', paddingRight: '0.1in', borderRight: '1px solid black' }}
					src={logo}
					alt="Logo de Mute"
				/>
				{sponsor && (
					<img
						src={sponsor}
						style={{
							marginLeft: 2,
							height: 'auto',
							width: 'auto',
							maxWidth: '.48in',
							maxHeight: '.3in',
							verticalAlign: 'center',
						}}
						alt="sponsor"
					/>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '50%' }}>
				<Typography textAlign={'end'} fontSize={9} variant="body1">
					{description ?? ''}
				</Typography>
				<Typography textAlign={'end'} fontSize={10} variant="body1">
					{!!id ? `ID: ${id}` : ''}
				</Typography>
			</Box>
		</Box>
	);
}

export default QRHeader;
