import { useCallback } from 'react';
import axios, { isAxiosError } from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSession, removeSession } from 'lib/storage';

import useStoreDispatch from 'store/useStoreDispatch';
import { setServerDown, setServerUp, tokenExpired } from 'store/reducers/session';

import { API_DOMAIN, ME_URL } from 'config/constants';
import Store from 'store/Store';

const { version } = require('../../../../package.json');

const api = axios.create({ baseURL: API_DOMAIN });
api.defaults.headers.common['app-version'] = `${version}`;
api.defaults.headers.common['app-device'] = 'back-office';

function useSetTokenExpired() {
	const dispatch = useStoreDispatch();

	const setExpired = useCallback(() => {
		dispatch(tokenExpired());
		removeSession();
	}, [dispatch]);

	return setExpired;
}

export default useSetTokenExpired;

const tokenExpiredOrServerDownThunk = createAsyncThunk('auth/handleTokenExpired', async (_, { dispatch }) => {
	const session = getSession();
	if (session && session.token) {
		api.defaults.headers.common['Authorization'] = `Bearer ${session.token}`;
		try {
			await api.get(ME_URL);
		} catch (error) {
			if (isAxiosError(error)) {
				// If the error is 401, the token is expired
				if (error.response?.status === 401) {
					dispatch(tokenExpired());
					removeSession();
					return;
				}
				// If the error is 404, the server is down
				if (error.code === 'ERR_NETWORK' || error?.response?.status === 404) {
					dispatch(setServerDown());
					// Start interval to check server status
					const intervalId = setInterval(async () => {
						try {
							await api.get(ME_URL);
							dispatch(setServerUp());
							clearInterval(intervalId); // Stop checking
						} catch {
							// Keep checking
						}
					}, 1000); // Check every 1 second
				}
			}
		}
	} else {
		dispatch(tokenExpired());
		removeSession();
	}
});

export const handleTokenExpiredOrServerDown = () => Store.dispatch(tokenExpiredOrServerDownThunk());
