import { fetchPopulatedMeShorcuts } from 'lib/models/shorcuts';
import { useCallback, useEffect, useState } from 'react';

function usePopulatedShorcuts() {
	const [loading, setLoading] = useState<boolean>(false);

	const [shorcuts, setShorcuts] = useState<PopulatedShorcut[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchPopulatedMeShorcuts();
		setShorcuts(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, shorcuts, refetch };
}

export default usePopulatedShorcuts;
