import { Grid, LinearProgress } from '@mui/material';
import DownloadPDFIcon from 'components/icons/DownloadPDFIcon';
import useUser from 'hooks/useUser';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { changeStatus, sendAccessByEmail } from 'lib/models/access';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import logoMute from '../../assets/images/mute.png';
import PrintIcon from 'components/icons/PrintIcon';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import ZebraAccess from './ZebraAccess';
import SendEmailIcon from 'components/icons/SendEmailIcon';

type Props = {
	accessCodes: Access[];
	sponsor: string;
	setLoading: Dispatch<SetStateAction<boolean>>;
	loading?: boolean;
	marginTop?: string;
	refetch?: () => void;
};

function AccessTickets({ accessCodes, loading, setLoading, refetch, sponsor }: Props) {
	const { t } = useTranslation();
	const location = useLocation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const canSendEmail = accessCodes.length === 1 && accessCodes[0].email;

	const route = location.pathname;

	const seeActions = isAdmin && route !== '/generate';

	const accessIds = accessCodes.map((a) => a.code);

	const onChangeStatus = async (id?: string) => {
		try {
			if (!id) throw new Error('Missing ID');
			const response = await changeStatus(id);

			if (!response) {
				throw new Error();
			}

			if (refetch) {
				refetch();
			}

			enqueueSnackbarSuccess(t('access:updatedAccess'));
		} catch (error) {
			enqueueSnackbarError(t('access:errorOnUpdate'));
		}
	};
	const contentRef = useRef<HTMLDivElement>(null);

	const generatePDF = async () => {
		try {
			if (contentRef.current) {
				setLoading(true);
				const buttons = document.querySelectorAll('.changeAccessStatus');
				buttons.forEach((button) => {
					//@ts-ignore
					button.style.display = 'none';
				});
				let pdfNumber = 1;

				const chunkSize = 100;
				const originalElement = contentRef.current;
				const originalHTML = originalElement.outerHTML;

				const clonedHTML = document.createElement('div');
				clonedHTML.innerHTML = originalHTML;

				const childDivs = Array.from(clonedHTML.querySelectorAll('.zebraTicket'));

				for (let i = 0; i < childDivs.length; i += chunkSize) {
					const chunkContainer = document.createElement('div');
					const chunk = childDivs.slice(i, i + chunkSize);

					chunk.forEach((div) => {
						//@ts-ignore
						div.style.border = '0px solid black';

						chunkContainer.appendChild(div);
					});

					// clonedHTML.appendChild(chunkContainer);

					await html2pdf()
						.set({
							filename: `Accesos_${pdfNumber}.pdf`,
							html2canvas: { scale: 2 },
							jsPDF: {
								unit: 'mm',
								format: [80, 50],
								orientation: 'portrait',
							},
							margins: { top: 0, left: 0, bottom: 0, right: 0 },
						})
						.from(chunkContainer)
						.save();

					pdfNumber++;
				}
			}
		} catch (error) {
			console.error('Error al generar el PDF:', error);
			enqueueSnackbarError(t('access:errorOnPDF'));
		} finally {
			const buttons = document.querySelectorAll('.changeAccessStatus');
			buttons.forEach((button) => {
				//@ts-ignore
				button.style.display = 'flex';
			});

			setLoading(false);
		}
	};

	const onSendEmail = async () => {
		let parent: ParentNode | null = null;
		let element: HTMLElement | null = null;
		let wrapper: HTMLElement | null = null;

		try {
			const accessId = accessCodes[0]._id;
			if (!accessId) {
				throw new Error('No se encontró el código');
			}
			element = document.getElementById(accessId);

			if (!element) {
				throw new Error('No se encontró el elemento');
			}

			const childElement = element.querySelector('.changeAccessStatus') as HTMLElement;

			if (childElement) {
				childElement.style.visibility = 'hidden';
			}

			wrapper = document.createElement('div');
			wrapper.style.display = 'flex';
			wrapper.style.justifyContent = 'center';
			wrapper.style.alignItems = 'center';
			wrapper.style.width = '55mm';
			wrapper.style.height = '84mm';

			parent = element.parentNode;
			if (!parent) {
				throw new Error('El elemento no tiene un nodo padre');
			}

			parent.replaceChild(wrapper, element);
			wrapper.appendChild(element);

			const options = {
				filename: 'document.pdf',
				html2canvas: { scale: 2 },
				jsPDF: {
					unit: 'mm',
					format: [85, 55],
					orientation: 'portrait',
				},
			};

			const pdfBlob = await html2pdf().from(wrapper).set(options).outputPdf('blob');

			const formData = new FormData();

			formData.append('file', pdfBlob);

			const { success } = await sendAccessByEmail(formData, accessCodes[0].code);

			if (success) {
				enqueueSnackbarSuccess(`Email enviado a ${accessCodes[0].email}`);
				childElement.style.visibility = 'visible';
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbarError('Hubo un error al enviar el mail');
		} finally {
			if (parent && element && wrapper) {
				wrapper.removeChild(element);
				parent.replaceChild(element, wrapper);
			}

			const childElement = document.querySelector('.changeAccessStatus') as HTMLElement;
			if (childElement) {
				childElement.style.visibility = 'visible';
			}
		}
	};

	return (
		<>
			{loading && <LinearProgress />}
			<DownloadPDFIcon onClick={generatePDF} />

			<Link to={`/print?codes=${accessIds}`} target="_blank">
				<PrintIcon disabled={false} />
			</Link>

			{canSendEmail && (
				<SendEmailIcon
					onClick={() => {
						onSendEmail();
					}}
				/>
			)}

			<Grid
				id="zebra-access-container"
				sx={{ mt: 2 }}
				container
				columns={12}
				justifyContent={'center'}
				rowSpacing={4}
				ref={contentRef}
			>
				{accessCodes.map((a) => {
					// return format === '6xp' || !format ? (
					// 	<A5Access
					// 		access={a}
					// 		logoMute={logoMute}
					// 		onChangeStatus={onChangeStatus}
					// 		seeToggleButton={seeToggleButton}
					// 		sponsor={sponsor}
					// 	/>
					// ) :
					return (
						<ZebraAccess
							access={a}
							logoMute={logoMute}
							onChangeStatus={onChangeStatus}
							seeActions={seeActions}
							sponsor={sponsor}
						/>
					);
				})}
			</Grid>
		</>
	);
}

export default AccessTickets;
