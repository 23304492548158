import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	onVerify: (code: string, serverIsDown: boolean) => Promise<void> | void;
	isDown: boolean;
	handleClose: () => void;
};

function ManuallyEnter({ open, onVerify, handleClose, isDown }: Props) {
	const { t } = useTranslation();

	const [code, setCode] = useState<string>('');

	const handleCode = (code: string) => {
		const parsedCode = code.replace(/\s/g, '').toLocaleUpperCase();
		setCode(parsedCode);
	};

	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">{t('access:enter_code')}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					autoComplete="off"
					value={code}
					onChange={(e) => handleCode(e.target.value)}
					inputProps={{
						style: { textTransform: 'uppercase', textAlign: 'center' },
						maxLength: 8,
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						setCode('');
					}}
					color="error"
				>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						onVerify(code, isDown);
						setCode('');
					}}
					disabled={!code || code.length !== 8}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ManuallyEnter;
