import { API_DOMAIN, TENT_OCCUPANCY_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { z } from 'zod';

export const zTentOccupancy = z.object({
	_id: z.optional(z.string()),
	tent: z.string(),
	lastName: z.string(),
	code: z.string(),
	quantity: z.number(),
	availableParking: z.number(),
});

type DataTentOccupancy = {
	total: number;
	elements: TentOccupancy[];
};

export async function fetchtentOccupancy(): Promise<DataTentOccupancy> {
	try {
		const response = await api.get<DataTentOccupancy>(`${API_DOMAIN}${TENT_OCCUPANCY_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (!Array.isArray(response.data.elements)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { total: 0, elements: [] };
	}
}
