import { API_DOMAIN, PARKING_OCCUPANCY_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

type DataParkingOccupancy = {
	total: number;
	elements: ParkingOccupancy;
};

export async function fetchParkingOccupancy(): Promise<DataParkingOccupancy> {
	try {
		const response = await api.get<DataParkingOccupancy>(`${API_DOMAIN}${PARKING_OCCUPANCY_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { total: 0, elements: {} as ParkingOccupancy };
	}
}
