import { Button, Typography } from '@mui/material';
import { ScanAndAssignPlate } from 'components/Modals';
import ManuallyEnter from 'components/Modals/ManuallyEntry';
import QRReader from 'components/QRReader/QRReader';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { canSavePlate, savePlate } from 'lib/models/access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AccessModals } from 'enums/access';
import useServerDown from 'hooks/useServerDown';

function ScanPlate() {
	const { t } = useTranslation();
	const isDown = useServerDown();

	const [openModal, setOpenModal] = useState<string | null>(null);
	const [foundCode, setFoundCode] = useState<string>('');

	const [activeCamera, setActiveCamera] = useState(true);
	const [isEnterPressed, setIsEnterPressed] = useState(false);

	const closeAll = () => {
		setOpenModal(null);
		setFoundCode('');
	};

	const handleScan = async (code: string) => {
		if (!!code && !foundCode) {
			try {
				await canSavePlate(code);

				setFoundCode(code);
				setOpenModal('ScanAndAssignPlate');
			} catch (error: any) {
				if (error.response.data.message) {
					return enqueueSnackbarError(error.response.data.message);
				}
				return enqueueSnackbarError('Error inesperado');
			}
		}
	};

	const onVerify = async (code: string) => {
		setFoundCode(code);
		setOpenModal('ScanAndAssignPlate');
		return;
	};

	const onSavePlate = async (plate: string) => {
		const result = await savePlate({ code: foundCode, plate });

		if (!result) {
			return enqueueSnackbarError('Error on save plate');
		}

		return enqueueSnackbarSuccess(t('common:success'));
	};

	const modals: Record<string, React.ReactNode> = {
		ManuallyEntry: <ManuallyEnter open={openModal} onVerify={onVerify} handleClose={closeAll} isDown={isDown} />,
		ScanAndAssignPlate: <ScanAndAssignPlate open={openModal} handleClose={closeAll} onSave={onSavePlate} />,
	};

	return (
		<>
			<Button
				size="medium"
				variant="text"
				component={Link}
				to="/"
				style={{ position: 'absolute', zIndex: 1400, left: '.5rem', top: '.5rem' }}
			>
				<ArrowBackIcon />
				<Typography sx={{ marginLeft: '0.5rem' }}>Escanear patente</Typography>
			</Button>
			{activeCamera ? (
				<QRReader onScan={handleScan} setOpenModal={setOpenModal} modalName={'ManuallyEntry'} routeName={'plate'} />
			) : (
				<div
					style={{
						padding: 0,
						margin: 0,
						width: '100vw',
						height: '100vh',
						overflow: 'hidden',
						backgroundColor: 'black',
						color: 'white',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{!openModal && (
						<p
							style={{
								marginTop: '-2rem',
							}}
						>
							Esperando lectura de código QR...
						</p>
					)}
				</div>
			)}
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '5.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setOpenModal(AccessModals.ManuallyEntry);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{t('access:manually_enter')}
			</Button>
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '1.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setActiveCamera((prev) => !prev);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{activeCamera ? 'Utilizar escáner' : 'Utilizar cámara'}
			</Button>
			{openModal && modals[openModal]}
		</>
	);
}

export default ScanPlate;
