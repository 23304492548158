import { useTranslation } from 'react-i18next';

import { Box, Button, ButtonGroup, Grid, Paper, Typography } from '@mui/material';
import { FindInPage as DocIcon } from '@mui/icons-material';

import useLoggedUser from 'hooks/useLoggedUser';
import { UsersTypes } from 'types/global';
import { useNavigate } from 'react-router-dom';

import FluidContainer from 'components/containers/FluidContainer';
import ShorcutsCardList from '../ShorcutsCardList';
import usePopulatedMeShorcuts from 'hooks/usePopulatedMeShorcuts';

function Dashboard() {
	const { t } = useTranslation();

	const { loading, shorcuts, refetch } = usePopulatedMeShorcuts();

	const user = useLoggedUser();
	const navigate = useNavigate();

	return (
		<FluidContainer title={t('common:dashboard')} sx={{ width: '100%' }}>
			<Grid container spacing={0}>
				{/* First Row */}
				<Grid item xs={12} sx={{ width: '100%' }}>
					<Grid container spacing={1}></Grid>
				</Grid>

				{/* Other Rows*/}
				<Grid item xs={12}>
					<Grid container spacing={4}></Grid>
				</Grid>
			</Grid>
			<Paper elevation={4} sx={{ marginTop: 4, padding: 4 }}>
				<Typography variant="body1" sx={{ paddingBottom: 4 }}>
					{t('common:welcome', { name: user?.first_name || '' })}
				</Typography>

				{user?.type === UsersTypes.admin && (
					<ButtonGroup>
						<Button
							onClick={() => {
								navigate(`/documentation`);
							}}
							type="button"
							variant="outlined"
							startIcon={<DocIcon />}
						>
							{t('common:documentation')}
						</Button>
					</ButtonGroup>
				)}
			</Paper>
			<Box>
				<ShorcutsCardList onEdit={() => null} refetch={refetch} data={shorcuts} loading={loading} />
			</Box>
		</FluidContainer>
	);
}

export default Dashboard;
