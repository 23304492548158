import { Approval, approve } from 'lib/models/products';
import { useCallback, useState } from 'react';

function useApproval() {
	const [loading, setLoading] = useState(false);
	const [approval, setApproval] = useState<string | null>(null);
	const [approvals, setApprovals] = useState<Approval[]>([]);

	const refetch = useCallback(async (regenerate: boolean) => {
		setLoading(true);
		const res = await approve(regenerate);
		setApproval(res?.adminKey ?? null);
		setApprovals(res?.approvals ?? []);
		setLoading(false);
	}, []);

	return {
		loading,
		approval,
		approvals,
		refetch,
	};
}

export default useApproval;
