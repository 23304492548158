import ScreenContainer from 'components/containers/ScreenContainer';

import GenerateCodeForm from 'components/GenerateAccessForm';
import useSponsor from 'hooks/useSponsor';
import useVehicles from 'hooks/useVehicles';
import { useSearchParams } from 'react-router-dom';

function GenerateCode() {
	const [searchParams] = useSearchParams();

	const accessId = searchParams.get('access');

	const { loading, vehicles } = useVehicles();
	const { sponsor } = useSponsor();

	const title = accessId ? `Editar acceso ${accessId}` : 'Generar acceso';

	return (
		<ScreenContainer title={title}>
			<GenerateCodeForm sponsor={sponsor} vehicles={vehicles} loading={loading} accessId={accessId} />
		</ScreenContainer>
	);
}

export default GenerateCode;
