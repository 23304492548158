import { fetchtentOccupancy } from 'lib/models/tentOccupancy';
import { useCallback, useEffect, useState } from 'react';

function useTentOccupancy() {
	const [loading, setLoading] = useState<boolean>(true);
	const [tentOccupancy, setTentOccupancy] = useState<TentOccupancy[]>([]);

	const refetch = useCallback(async () => {
		const response = await fetchtentOccupancy();
		setTentOccupancy(response.elements);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, tentOccupancy, refetch, setLoading };
}

export default useTentOccupancy;
