import { fetchParkingOccupancy } from 'lib/models/parkingOccupancy';
import { useCallback, useEffect, useState } from 'react';

function useParkingOccupancy() {
	const [loading, setLoading] = useState<boolean>(true);
	const [parkingOccupancy, setTentOccupancy] = useState<ParkingOccupancy>({} as ParkingOccupancy);

	const refetch = useCallback(async () => {
		const response = await fetchParkingOccupancy();
		setTentOccupancy(response.elements);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, parkingOccupancy, refetch, setLoading };
}

export default useParkingOccupancy;
