/* Card Holder ID edition is only present in dev and staging */

import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { REACT_APP_ENVIRONMENT } from 'config/constants';

import { getCardHolderId, updateCardHolderId } from 'lib/models/profile';
import { useEffect, useState } from 'react';

type Props = {
	onClose: () => void;
	username?: string;
};

const inDevOrStaging = ['dev', 'staging'].includes(REACT_APP_ENVIRONMENT);

function CardHolderId({ onClose, username }: Props) {
	const [loading, setLoading] = useState(true);

	const [value, setValue] = useState('');

	const onSave = async () => {
		try {
			if (!username) {
				throw new Error("can't save without username");
			}
			await updateCardHolderId(username, value);
			onClose();
		} catch (error) {
			console.warn(error);
		}
	};

	useEffect(() => {
		const search = async () => {
			setLoading(true);
			if (!username) {
				setValue('');
			} else {
				try {
					const response = await getCardHolderId(username);
					setValue(response);
				} catch (error) {
					console.warn(error);
				}
			}
			setLoading(false);
		};
		search();
	}, [username]);

	return inDevOrStaging && username ? (
		<Box
			sx={{
				padding: 2,
				gap: 2,
				backgroundColor: 'white',
				borderRadius: 5,
				borderColor: 'gray',
				borderWidth: 1,
				borderStyle: 'dotted',
			}}
		>
			<Box sx={{ mb: 2, color: 'red' }}>Esta funcionalidad está presente sólo en dev y en staging.</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				{loading && <CircularProgress />}
				<TextField
					value={value}
					label={'Card Holder ID'}
					onChange={(ev) => setValue(ev.target.value)}
					disabled={loading}
				/>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
					<Button
						variant="contained"
						color="primary"
						onClick={onSave}
						disabled={!username || !value || username === value}
					>
						Guardar
					</Button>
					<Button variant="contained" color="error" onClick={onClose}>
						Cerrar
					</Button>
				</Box>
			</Box>
		</Box>
	) : null;
}

export default CardHolderId;
