import { getResetParkingCount } from 'lib/models/access';
import { useCallback, useEffect, useState } from 'react';
function useResetParkingCount() {
	const [count, setCount] = useState(0);
	const [success, setSuccess] = useState<boolean>();

	const refetch = useCallback(async () => {
		const { count, success } = await getResetParkingCount();
		setCount(count);
		setSuccess(success);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { count, success, refetch };
}

export default useResetParkingCount;
