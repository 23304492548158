import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, LinearProgress, MenuItem, Select, Typography } from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';

import ConfirmationDialog from 'components/ConfirmationDialog';
import PaperContainer from 'components/containers/PaperContainer';
import { deleteSection, SectionMode, sectionTypes } from 'lib/models/sections';
import { price } from 'lib/numbers';
import ProductModal from 'components/ProductModal';
import ProductCategoryModal from 'components/ProductCategoryModal';

type Props = {
	loading: boolean;
	data: PopulatedProductSection[];
	onEdit: (section: PopulatedProductSection) => void;
	refetch: () => void;
};

export default function SectionsList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [type, setType] = useState('all');
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedProductSection | null>(null);
	const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

	const [showProductModal, setShowProductModal] = useState<Product | null>(null);
	const [showProductCategoryModal, setShowProductCategoryModal] = useState<PopulatedProductCategory | null>(null);

	const onCloseProductModal = () => {
		refetch();
		setShowProductModal(null);
	};

	const onCloseProductCategoryModal = () => {
		refetch();
		setShowProductCategoryModal(null);
	};

	const toggleExpand = (id: string) => {
		setExpandedSections((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const onDelete = (section: PopulatedProductSection) => {
		setShowDeleteConfirmation(section);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			if (showDeleteConfirmation.categories.length > 0) {
				setShowDeleteConfirmation(null);
				return enqueueSnackbarError(t('product:errorHasItems'));
			}
			const deleted = await deleteSection(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}

			{showProductModal && <ProductModal defaultValue={showProductModal} onClose={onCloseProductModal} />}

			{showProductCategoryModal && (
				<ProductCategoryModal
					defaultValue={showProductCategoryModal}
					onClose={onCloseProductCategoryModal}
					refetch={refetch}
				/>
			)}

			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Select
					labelId="type"
					id="type"
					fullWidth
					value={type}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={(ev) => setType(ev.target.value)}
					disabled={loading}
				>
					<MenuItem value="all">{type === 'all' ? 'Filtrar por tipo de Punto de venta' : 'Todo'}</MenuItem>
					{sectionTypes.map((sectionType) => (
						<MenuItem key={`type-${sectionType}`} value={sectionType}>
							{t(`common:${sectionType}`)}
						</MenuItem>
					))}
				</Select>
			</Box>

			<Box>
				{data.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{data
					.filter((el) => type === 'all' || el.type === type)
					.map((el) => {
						const isExpanded = expandedSections[`${el._id}`] ?? false;

						return (
							<PaperContainer
								key={el._id}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									mb: 2,
									position: 'relative',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										cursor: 'pointer',
									}}
									onClick={() => toggleExpand(`${el._id}`)}
								>
									<Typography variant="h6">
										<strong>{el.title.toUpperCase()}</strong>
									</Typography>

									<IconButton>
										<ExpandMore
											sx={{
												transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
												transition: 'transform 0.3s',
											}}
										/>
									</IconButton>
								</Box>

								<Collapse in={isExpanded}>
									<Box sx={{ p: 2 }}>
										<Typography sx={{ mb: 2 }} variant="body1">
											<span>
												{el.mode && el.mode !== SectionMode.all
													? t(`common:${el.mode}`)
													: `${t(`common:beach`)} y ${t(`common:events`)}`}
											</span>
											<span> ({t(`common:${el.type}`)})</span>
										</Typography>

										{el.categories.map((category) => (
											<Box key={category.title}>
												<Typography
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'space-between',
														backgroundColor: '#fff2cc',
														padding: 1,
														mb: 1,
													}}
												>
													<strong>
														{category.title}
														{category.vip && <span> (VIP)</span>}
													</strong>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'center',
															alignItems: 'center',
															gap: 1,
														}}
													>
														<Edit
															sx={{ cursor: 'pointer' }}
															onClick={() => setShowProductCategoryModal(category)}
															color="primary"
														/>
													</Box>
												</Typography>

												<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
													{category.items.map((item) => (
														<Box
															key={item.title}
															sx={{
																display: 'flex',
																flexDirection: 'column',
															}}
														>
															<Box
																sx={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	gap: 8,
																	minWidth: '500px',
																}}
															>
																<Typography>{item.title}</Typography>
																<Typography sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
																	<strong>{price(item.price ?? 0)}</strong>
																	<Box
																		sx={{
																			display: 'flex',
																			flexDirection: 'row',
																			justifyContent: 'center',
																			alignItems: 'center',
																			gap: 1,
																		}}
																	>
																		<Edit
																			sx={{ cursor: 'pointer' }}
																			onClick={() => setShowProductModal(item)}
																			color="primary"
																		/>
																	</Box>
																</Typography>
															</Box>
															{(item.mixer?.elements?.length ?? 0) > 0 && (
																<Typography variant="caption">
																	{item.mixer?.elements.join(', ')}
																	{item.mixer && (
																		<strong> {`(${item.mixer.optional ? 'opcional' : 'requerido'})`}</strong>
																	)}
																</Typography>
															)}
														</Box>
													))}
												</Box>
											</Box>
										))}
									</Box>
								</Collapse>

								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										gap: 1,
										position: 'absolute',
										top: 24,
										right: 64,
									}}
								>
									<Edit sx={{ cursor: 'pointer' }} onClick={() => onEdit(el)} color="primary" />
									<Delete sx={{ cursor: 'pointer' }} onClick={() => onDelete(el)} color="secondary" />
								</Box>
							</PaperContainer>
						);
					})}
			</Box>
		</Box>
	);
}
