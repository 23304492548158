import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, Save } from '@mui/icons-material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	LinearProgress,
	FormGroup,
	TextField,
	Button,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Checkbox,
	Switch,
	Autocomplete,
	createFilterOptions,
} from '@mui/material';

import { enqueueSnackbarError } from 'lib/helpers';
import { createProduct, updateProduct } from 'lib/models/products';

import useProductCategories from 'hooks/useProductCategories';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useProductMixers from 'hooks/useProductMixers';

type Props = {
	defaultValue: Product;
	onClose: (product?: Product | null) => void;
};

interface MixerOptionType {
	inputValue?: string;
	value: string;
}

const filter = createFilterOptions<MixerOptionType>();

export default function ProductModal({ defaultValue, onClose }: Props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState<Product>(defaultValue);

	const categories = product.categories || [];

	const { productCategories } = useProductCategories();

	const { mixers } = useProductMixers();

	const options: MixerOptionType[] = mixers.map((c) => {
		return { value: c, inputValue: c };
	});

	const successSnackbar = useSuccessSnackbar();

	const canSave = !!product.price && !!product.title && product.categories.length > 0;

	const onUpdate = (key: keyof Product) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setProduct({ ...product, [key]: value });
		};
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps

	const toggleMixerOptional = () => {
		setProduct((current) => {
			return {
				...current,
				mixer: {
					optional: !current.mixer?.optional,
					elements: current.mixer?.elements ?? [],
				},
			};
		});
	};

	const addMixer = (mixer: string) => {
		setProduct((current) => {
			const elements = current.mixer?.elements ?? [];
			return {
				...current,
				mixer: {
					optional: current.mixer?.optional ?? true,
					elements: elements.includes(mixer) ? elements : [...elements, mixer],
				},
			};
		});
	};

	const removeMixer = (mixer: string) => {
		setProduct((current) => {
			const elements = current.mixer?.elements ?? [];
			return {
				...current,
				mixer: {
					optional: current.mixer?.optional ?? true,
					elements: elements.filter((el) => el !== mixer),
				},
			};
		});
	};

	const save = async () => {
		setLoading(true);
		try {
			const saved = await (product._id ? updateProduct(product) : createProduct(product));
			setLoading(false);
			successSnackbar();
			onClose(saved);
		} catch (error) {
			enqueueSnackbarError(error);
		}
		onClose(product);
		setLoading(false);
	};

	return (
		<Dialog open onClose={() => onClose(product)} fullWidth maxWidth="lg">
			{loading && <LinearProgress />}
			<DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>{t('common:product')}</div>
				<div style={{ cursor: 'pointer' }} onClick={() => onClose()}>
					X
				</div>
			</DialogTitle>
			<DialogContent>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{product && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={product.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
							required={true}
						/>
						<TextField
							id="title"
							label={t('common:description')}
							fullWidth={true}
							value={product.description}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('description')}
							disabled={loading}
							required={false}
						/>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', gap: '16px' }}>
							<TextField
								id="price"
								type="number"
								label={t('common:price')}
								fullWidth={true}
								value={product.price}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('price')}
								disabled={loading}
							/>
							<TextField
								id="promotionPrice"
								type="number"
								label={t('common:promotionPrice')}
								fullWidth={true}
								value={product.promotionPrice}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('promotionPrice')}
								disabled={loading}
							/>
						</div>

						<Typography variant="h6">Categorías seleccionadas</Typography>

						<Typography variant="caption" sx={{ color: 'red' }}>
							ADVERTENCIA: Esta pantalla (a diferencia de la pantalla de categorías) NO guarda automáticamente la
							selección, deberás presionar el botón "GUARDAR" para efectivizar el guardado.
						</Typography>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Título
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{productCategories.map((productCategory) => {
									return (
										<TableRow key={`product-category-table-${productCategory}`}>
											<TableCell align="left" padding="normal">
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>
													{productCategory.title}
													{productCategory.vip && <Typography variant="caption">(VIP)</Typography>}
													<Typography variant="caption">{productCategory.description}</Typography>
												</Box>
											</TableCell>
											<TableCell align="left" padding="normal">
												<Checkbox
													onChange={() => {
														setProduct((current) => {
															return {
																...current,
																categories: categories.includes(`${productCategory._id}`)
																	? categories.filter((el) => el !== `${productCategory._id}`)
																	: [...categories, `${productCategory._id}`],
															};
														});
													}}
													checked={categories.includes(`${productCategory._id}`)}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>

						<Typography variant="h6" sx={{ mt: 4 }}>
							Acompañamientos
						</Typography>

						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<Typography variant="caption">Opcional</Typography>
							<Switch checked={product.mixer?.optional} onChange={toggleMixerOptional} />
						</Box>
						<Typography variant="caption">Agregar</Typography>
						<Autocomplete
							loading={loading}
							id="sizes"
							// value={mixer}
							sx={{ flex: 1 }}
							options={options}
							renderInput={(params) => <TextField {...params} />}
							renderOption={(props, option) => <li {...props}>{option.value}</li>}
							onChange={(_, newValue) => {
								if (newValue?.inputValue && newValue.value) {
									addMixer(newValue.value.replace(`${t('common:create')}: `, ''));
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;

								const value = inputValue.trim();

								const exists = options.some((option) => value === option.inputValue);

								if (value !== '' && !exists) {
									filtered.push({
										inputValue: value,
										value: `${t('common:create')}: ${value}`,
									});
								}
								return filtered;
							}}
							getOptionLabel={(option) => {
								return option.inputValue || option.value;
							}}
						/>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Acompañamiento
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(product.mixer?.elements || ([] as string[])).map((mixer) => {
									return (
										<TableRow key={`product-mixer-table-${mixer}`}>
											<TableCell align="left" padding="normal">
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>{mixer}</Box>
											</TableCell>
											<TableCell align="left" padding="normal">
												<Checkbox onChange={() => removeMixer(mixer)} checked={true} />
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1, mt: 4 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!canSave}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={() => onClose(product)}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}
