import { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormGroup,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useProductCategories from 'hooks/usePopulatedProductCategories';

import { enqueueSnackbarError } from 'lib/helpers';
import { createSection, SectionMode, sectionTypes, updateSection } from 'lib/models/sections';
import ProductCategoryModal from 'components/ProductCategoryModal';

type Props = {
	defaultValue: PopulatedProductSection;
	onClose: () => void;
	refetch: () => void;
};

export default function SectionModal({ defaultValue, onClose, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [loading, setLoading] = useState(false);
	const [section, setSection] = useState(defaultValue);
	const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

	const [showProductCategoryModal, setShowProductCategoryModal] = useState<PopulatedProductCategory | null>(null);

	const { productCategories, loading: loadingCategories, refetch: refetchProductCategories } = useProductCategories();

	const [searchValue, setSearch] = useState('');

	const onUpdate = (key: keyof ProductSection) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setSection({ ...section, [key]: value });
		};
	};

	const onSelectUpdate = (key: keyof ProductSection) => {
		return (e: SelectChangeEvent) => {
			const { value } = e.target;
			setSection({ ...section, [key]: value });
		};
	};

	const handleDragStart = (index: number) => {
		setDraggingIndex(index);
	};

	const handleDragOver = (event: DragEvent<HTMLTableRowElement>, index: number) => {
		event.preventDefault(); // Prevent the default behavior (like animation)

		if (draggingIndex === index || draggingIndex === null) return;

		const updatedCategories = [...section.categories];
		const draggedItem = updatedCategories[draggingIndex];

		// Remove dragged item and insert it at the new index
		updatedCategories.splice(draggingIndex, 1);
		updatedCategories.splice(index, 0, draggedItem);

		setDraggingIndex(index);

		setSection((current) => ({ ...current, categories: updatedCategories }));
	};

	const handleDrop = () => {
		setDraggingIndex(null); // Clear dragging state
	};

	const save = async () => {
		setLoading(true);
		try {
			await (section?._id ? updateSection(section) : createSection(section));
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		onClose();
	};

	const onCloseProductCategoryModal = () => {
		refetch();
		refetchProductCategories();
		setShowProductCategoryModal(null);
	};

	return (
		<Dialog open fullWidth={true} maxWidth="lg">
			{loading && <LinearProgress />}
			{showProductCategoryModal && (
				<ProductCategoryModal
					defaultValue={showProductCategoryModal}
					onClose={onCloseProductCategoryModal}
					refetch={refetch}
					forceClose={true}
				/>
			)}

			<DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>{t('product:sections')}</div>
				<div style={{ cursor: 'pointer' }} onClick={() => onClose()}>
					X
				</div>
			</DialogTitle>
			<DialogContent>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{section && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={section.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
						/>

						<Box sx={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
							<FormControl sx={{ minWidth: 250 }}>
								<InputLabel>Tipo</InputLabel>
								<Select
									labelId="type"
									id="type"
									label="Tipo"
									fullWidth={true}
									value={section.type}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={onSelectUpdate('type')}
									disabled={loading}
								>
									{sectionTypes.map((sectionType) => {
										return (
											<MenuItem key={`type-${sectionType}`} value={sectionType}>
												{t(`common:${sectionType}`)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							<FormControl sx={{ minWidth: 250 }}>
								<InputLabel>Modo</InputLabel>
								<Select
									labelId="mode"
									id="mode"
									label="Modo"
									value={section.mode || 'all'}
									onChange={onSelectUpdate('mode')}
									required={false}
								>
									<MenuItem value={'all'}>{t('common:any')}</MenuItem>
									<MenuItem value={SectionMode.beach}>Playa</MenuItem>
									<MenuItem value={SectionMode.events}>Eventos</MenuItem>
								</Select>
							</FormControl>

							<FormControl sx={{ minWidth: 250 }}>
								<InputLabel>Permitir promociones</InputLabel>
								<Select
									labelId="status"
									id="status"
									label="Permitir promociones"
									fullWidth={true}
									value={section.allowsPromotionPrice ? 'y' : 'n'}
									variant="outlined"
									sx={{ mb: 3 }}
									onChange={(ev) => {
										setSection({ ...section, allowsPromotionPrice: ev.target.value === 'y' });
									}}
									disabled={loading}
								>
									<MenuItem value="y">Permitir</MenuItem>
									<MenuItem value="n">No permitir</MenuItem>
								</Select>
							</FormControl>
						</Box>

						{section.categories.length > 0 && <Typography variant="h6">Categorías seleccionadas</Typography>}

						{section.categories.length > 0 && (
							<Typography variant="caption" sx={{ color: 'red' }}>
								ADVERTENCIA: Esta pantalla (a diferencia de la pantalla de categorías) NO guarda automáticamente la
								selección, deberás presionar el botón "GUARDAR" para efectivizar el guardado.
							</Typography>
						)}

						{loadingCategories && <CircularProgress />}

						{section.categories.length > 0 && (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left" padding="normal">
											Título
										</TableCell>
										<TableCell align="left" padding="normal">
											Acciones
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{section.categories.map((productCategory, index) => {
										return (
											<TableRow
												key={`section-category-table-${productCategory._id}`}
												onDragStart={() => handleDragStart(index)}
												onDragOver={(ev) => handleDragOver(ev, index)}
												draggable={true}
												onDrop={handleDrop}
												style={{
													backgroundColor: draggingIndex === index ? '#f0f0f0' : 'transparent',
													cursor: 'move',
												}}
											>
												<TableCell align="left" padding="normal">
													<Box sx={{ display: 'flex', flexDirection: 'column' }}>
														<div
															onClick={() => setShowProductCategoryModal(productCategory)}
															style={{ cursor: 'pointer', width: 'fit-content' }}
														>
															{productCategory.title}
														</div>
														<Typography variant="caption">{productCategory.description}</Typography>
													</Box>
												</TableCell>
												<TableCell align="left" padding="normal">
													<Checkbox
														onChange={() => {
															setSection((current) => {
																return {
																	...current,
																	categories: current.categories.filter(
																		(category) => category._id !== productCategory._id
																	),
																};
															});
														}}
														checked={true}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						)}

						<Typography
							variant="h6"
							sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
						>
							Seleccionar categoría
							<Button
								variant="contained"
								onClick={() => {
									setShowProductCategoryModal({
										items: [],
										title: '',
									});
								}}
							>
								Crear categoría
							</Button>
						</Typography>

						{section.categories.length === 0 && (
							<Typography variant="caption" sx={{ color: 'red' }}>
								ADVERTENCIA: Esta pantalla (a diferencia de la pantalla de categorías) NO guarda automáticamente la
								selección, deberás presionar el botón "GUARDAR" para efectivizar el guardado.
							</Typography>
						)}

						{loadingCategories && <CircularProgress />}

						<TextField
							fullWidth
							value={searchValue}
							label={'Buscar'}
							onChange={(ev) => setSearch(ev.target.value)}
							sx={{ mt: 2 }}
						/>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Título
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{productCategories
									.filter((current) => !withCategory(section, current))
									.filter(filterProductCategories(normalizeText(searchValue)))
									.map((productCategory) => {
										return (
											<TableRow key={`category-table-${productCategory._id}`}>
												<TableCell align="left" padding="normal">
													<Box sx={{ display: 'flex', flexDirection: 'column' }}>
														<div
															onClick={() => setShowProductCategoryModal(productCategory)}
															style={{ cursor: 'pointer', width: 'fit-content' }}
														>
															{productCategory.title}
														</div>
														{productCategory.vip && <Typography variant="caption">(VIP)</Typography>}
														<Typography variant="caption">{productCategory.description}</Typography>
													</Box>
												</TableCell>
												<TableCell align="left" padding="normal">
													<Checkbox
														onChange={() => {
															setSection((current) => {
																return {
																	...current,
																	categories: [...current.categories, productCategory],
																};
															});
														}}
														checked={false}
													/>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!section.title}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}

function withCategory(section: PopulatedProductSection, productCategory: PopulatedProductCategory | ProductCategory) {
	return !!section.categories.find((category) => category._id === productCategory._id);
}

function normalizeText(text: string): string {
	return text
		.normalize('NFD') // Decomposes characters (e.g., "é" → "é")
		.replace(/[\u0300-\u036f]/g, '') // Removes the diacritic marks while keeping base letters
		.toLowerCase();
}

function filterProductCategories(searchValue: string) {
	const normalizedSearch = normalizeText(searchValue);

	return (p: PopulatedProductCategory) => {
		return normalizeText(p.title).includes(normalizedSearch);
	};
}
