/*import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Delete, Edit } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardActions,
	CardContent,
	IconButton,
	LinearProgress,
	Typography,
} from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { enqueueSnackbarError } from 'lib/helpers';

import ConfirmationDialog from 'components/ConfirmationDialog';
//import PaperContainer from 'components/containers/PaperContainer';
import { deleteShorcut } from 'lib/models/shorcuts';
import { UsersTypes } from 'types/global';

type Props = {
	loading: boolean;
	data: PopulatedShorcut[];
	onEdit: (shorcut: PopulatedShorcut) => void;
	refetch: () => void;
};

export default function ShorcutsList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedShorcut | null>(null);

	const groupedData = data.reduce<Record<string, PopulatedShorcut[]>>((groups, shorcut) => {
		if (!groups[shorcut.userType]) {
			groups[shorcut.userType] = [];
		}
		groups[shorcut.userType].push(shorcut);
		return groups;
	}, {});

	const sortedUserTypes = Object.keys(groupedData).sort();

	const onDelete = (shorcut: PopulatedShorcut) => {
		setShowDeleteConfirmation(shorcut);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			const deleted = await deleteShorcut(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	const getUserTypeLabel = (userType: string) => {
		switch (userType) {
			case UsersTypes.admin:
				return t('common:role_admin');
			case UsersTypes.parkingAdmin:
				return t('common:role_parkingAdmin');
			case UsersTypes.parkingGate:
				return t('common:role_parkingGate');
			case UsersTypes.panel:
				return t('common:role_panel');
			case UsersTypes.barAdmin:
				return t('common:role_barAdmin');
			case UsersTypes.barPanel:
				return t('common:role_barPanel');
			case UsersTypes.barCashier:
				return t('common:role_barCashier');
			default:
				return userType;
		}
	};

	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			<Box>
				{sortedUserTypes.length === 0 ? (
					<Typography>{t('common:withoutElements')}</Typography>
				) : (
					sortedUserTypes.map((userType) => {
						const items = groupedData[userType].slice().sort((a, b) => a.order - b.order);
						return (
							<Accordion key={userType} defaultExpanded>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography
										variant="h6"
										sx={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
										}}
									>
										{getUserTypeLabel(userType)}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{items.map((el) => (
										<Card
											key={el._id}
											sx={{
												mb: 2,
												border: '2px solid',
												borderColor: 'grey.300',
												borderRadius: 2,
												cursor: 'pointer',
												transition: 'transform 0.2s',
												'&:hover': { transform: 'scale(1.02)' },
											}}
										>
											<CardContent>
												<Typography variant="h5" component="div" gutterBottom>
													{el.title}
												</Typography>
												<Typography variant="body2" color="text.secondary" gutterBottom>
													{el.description}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{t('common:order')}: {el.order}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{t('common:textBottom')}: {el.textBottom}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{t('common:link')}: {el.link}
												</Typography>
												<Typography variant="body2" color="text.secondary">
													{t('common:color')}: {el.backgroundColor}
												</Typography>
											</CardContent>
											<CardActions sx={{ justifyContent: 'flex-end' }}>
												<IconButton onClick={() => onEdit(el)} color="primary">
													<Edit />
												</IconButton>
												<IconButton onClick={() => onDelete(el)} color="secondary">
													<Delete />
												</IconButton>
											</CardActions>
										</Card>
									))}
								</AccordionDetails>
							</Accordion>
						);
					})
				)}
			</Box>
		</Box>
	);
}*/

//import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Avatar,
	ListItemText,
	TableHead,
	TableRow,
	TableCell,
	ButtonGroup,
	LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { rowsPerPageOptions } from 'config/constants';
import { useState } from 'react';
import React from 'react';
import { enqueueSnackbarError } from 'lib/helpers';

import ResponsiveTable from 'components/ResponsiveTable';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';
import { deleteShorcut } from 'lib/models/shorcuts';
import ConfirmationDialog from 'components/ConfirmationDialog';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
/*
type Props = {
	loading: boolean;
	data: PopulatedShorcut[];
	editUrl?: string;
	refetch: () => void;
};*/

/*
type Props = {
	loading: boolean;
	data: PopulatedShorcut[];
	onEdit: (shorcut: PopulatedShorcut) => void;
	refetch: () => void;
};
*/
type Props = {
	loading?: boolean;
	editUrl?: string;
	shorcuts: PaginateSource<PopulatedShorcut>;
	paper?: boolean;
	disabled?: boolean;
	refetch: () => void;
	customAction?: CustomTooltipProps;
	onEdit: (shorcut: PopulatedShorcut) => void;
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (comment: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function ShorcutsCardList(props: Props) {
	const { t } = useTranslation();

	//const navigate = useNavigate();

	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedShorcut | null>(null);

	/*function onEdit(selected: PopulatedShorcut) {
		const { _id } = selected;
		navigate(`/${props.editUrl || 'parking/vehicles'}/${_id}`);
	}*/

	const truncateText = (text: string, maxLength: number) => {
		if (text.length > maxLength) {
			return text.slice(0, maxLength) + '...';
		}
		return text;
	};

	const onDelete = (shorcut: PopulatedShorcut) => {
		setShowDeleteConfirmation(shorcut);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			const deleted = await deleteShorcut(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				props.refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!props.loading && <LinearProgress />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}

			{!props.disabled && (
				<ResponsiveTable
					elements={props.shorcuts.elements}
					listSx={{ maxHeight: '70vh' }}
					tableSx={{ maxHeight: '65vh' }}
					list={{
						primaryKey: '_id',
						avatar: <Avatar />,
						renderListItemText: (shorcut) => {
							return (
								<ListItemText
									onClick={() => props.onEdit(shorcut)}
									primary={`${shorcut.title || ''}`}
									secondary={
										<>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'start',
													'& > *': {
														m: 1,
													},
												}}
											>
												{`${shorcut.description || ''}`}
											</Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'start',
													'& > *': {
														m: 1,
													},
												}}
											>
												{`${t('common:rol')}: ${shorcut.userType || ''}`}
											</Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'start',
													'& > *': {
														m: 1,
													},
												}}
											>
												{`${t('common:link')}: ${shorcut.link || ''}`}
											</Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'start',
													'& > *': {
														m: 1,
													},
												}}
											>
												{`${t('common:order')}: ${shorcut.order || ''}`}
											</Box>

											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'start',
													'& > *': {
														m: 1,
													},
												}}
											>
												<ButtonGroup size="small" aria-label="Small button group">
													<Button key="one" onClick={() => props.onEdit(shorcut)} variant="outlined">
														{t(`common:edit`)}
													</Button>
													<Button key="two" color="error" onClick={() => onDelete(shorcut)} variant="outlined">
														{t(`common:delete`)}
													</Button>
												</ButtonGroup>
											</Box>
										</>
									}
								/>
							);
						},
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										{t('common:rol')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:title')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:link')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:description')}
									</TableCell>
									{/* <TableCell align="left" padding="normal">
										{t('common:priceHour')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:priceHalfStay')}
									</TableCell> */}
									<TableCell align="left" padding="normal">
										{t('common:order')}
									</TableCell>

									<TableCell align="left" padding="normal">
										{t('common:color')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:textBottom')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:actions')}
									</TableCell>
								</TableRow>
							</TableHead>
						),
						renderRow: (shorcut) => {
							return (
								<TableRow tabIndex={-1} key={shorcut._id}>
									<TableCell>{shorcut.userType}</TableCell>
									<TableCell>{shorcut.title}</TableCell>
									<TableCell>{shorcut.link}</TableCell>
									<TableCell>{shorcut.description ? truncateText(shorcut.description, 10) : ''}</TableCell>
									{/* <TableCell>{vehicle.priceHour}</TableCell>
									<TableCell>{vehicle.priceHalfStay}</TableCell> */}
									<TableCell>{shorcut.order}</TableCell>
									<TableCell>{shorcut.backgroundColor}</TableCell>
									<TableCell>{shorcut.textBottom}</TableCell>
									<TableCell>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												'& > *': {
													m: 1,
												},
											}}
										>
											<ButtonGroup size="small" aria-label="Small button group">
												<Button key="one" onClick={() => props.onEdit(shorcut)} variant="outlined">
													{t(`common:edit`)}
												</Button>
												<Button key="two" color="error" onClick={() => onDelete(shorcut)} variant="outlined">
													{t(`common:delete`)}
												</Button>
											</ButtonGroup>
										</Box>
									</TableCell>
								</TableRow>
							);
						},
						pagination: props.disabled
							? null
							: {
									count: props.shorcuts.total,
									page: props.page,
									rowsPerPage: props.rowsPerPage,
									rowsPerPageOptions,
									onPageChange: props.handleChangePage,
									onRowsPerPageChange: props.handleChangeRowsPerPage,
							  },
					}}
				/>
			)}
		</Box>
	);
}
