import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme, ListItemText, useMediaQuery, TableHead, TableRow, TableCell, Box, Stack, Chip } from '@mui/material';

import GarageIcon from '@mui/icons-material/Garage';
import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

import { rowsPerPageOptions } from 'config/constants';
import { sanitizedInput } from 'lib/helpers';

type Props = {
	loading?: boolean;
	editUrl?: string;
	tentOccupancy: TentOccupancy[];
	paper?: boolean;
	disabled?: boolean;
	refetch?: () => {};
	customAction?: CustomTooltipProps;
};

export default function TentOccupancyTable(props: Props) {
	const { t } = useTranslation();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [search, setSearch] = useState('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchValue = sanitizedInput(search);

	const tentOccupancys = props.tentOccupancy.filter((tent) => {
		return (
			!searchValue ||
			sanitizedInput(tent.tent).includes(searchValue) ||
			sanitizedInput(tent.lastName).includes(searchValue)
		);
	});

	const maxQuantity = Math.max(...tentOccupancys.map((item) => item.quantity || 0));
	return (
		<FullLayout paper={props.paper}>
			<SearchToolbar
				onSearch={(value) => setSearch(value)}
				live={true}
				disabled={props.disabled}
				customAction={props.customAction}
			/>

			{!props.disabled && (
				<ResponsiveTable
					elements={
						isMobile ? tentOccupancys : tentOccupancys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					}
					list={{
						primaryKey: '_id',
						avatar: <GarageIcon />,
						renderListItemText: (tentOccupancy) => {
							return (
								<ListItemText
									onClick={() => false}
									primary={`${tentOccupancy.tent || ''}, ${tentOccupancy.lastName || ''}`}
									secondary={`${t('common:parkings')}: ${tentOccupancy.quantity || 0}, \t\t     
									${t('common:availableParking')}: ${tentOccupancy.availableParking || 0}`}
								/>
							);
						},
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell rowSpan={2} align="left" padding="normal" sx={{ verticalAlign: 'bottom' }}>
										{t('common:tent')}
									</TableCell>
									<TableCell rowSpan={2} align="left" padding="normal" sx={{ verticalAlign: 'bottom' }}>
										{t('common:lastName')}
									</TableCell>
									<TableCell rowSpan={2} align="left" padding="normal" sx={{ verticalAlign: 'bottom' }}>
										{t('common:code')}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="left" padding="normal" colSpan={1}>
										{t('common:cant.')}
									</TableCell>
									{
										//hacer un map utilizando la cantidad de cocheras
										<TableCell align="center" padding="normal" colSpan={maxQuantity}>
											{t('common:availableParking')}
										</TableCell>
									}
								</TableRow>
							</TableHead>
						),
						renderRow: (tentOccupancy) => {
							return (
								<TableRow tabIndex={-1} key={tentOccupancy._id}>
									<TableCell>{tentOccupancy.tent}</TableCell>
									<TableCell>{tentOccupancy.lastName}</TableCell>
									{/* <TableCell>{vehicle.priceHour}</TableCell>
									<TableCell>{vehicle.priceHalfStay}</TableCell> */}
									<TableCell>{tentOccupancy.code}</TableCell>
									<TableCell>{tentOccupancy.quantity}</TableCell>
									{Array.from({ length: maxQuantity }).map((_, i) => {
										if (i < tentOccupancy.quantity) {
											const occupiedCount = tentOccupancy.quantity - tentOccupancy.availableParking;
											const isOccupied = i < occupiedCount;
											return (
												<TableCell key={i}>
													<Box>
														<Stack direction="row" spacing={0.1}>
															<Chip
																label={isOccupied ? 'Ocupado' : 'Disponible'}
																color={isOccupied ? 'error' : 'success'}
															/>
														</Stack>
													</Box>
												</TableCell>
											);
										} else {
											return (
												<TableCell key={i}>
													<Box>
														<Stack direction="row" spacing={1}>
															<Chip
																label={
																	'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
																}
																color="default"
															/>
														</Stack>
													</Box>
												</TableCell>
											);
										}
									})}
								</TableRow>
							);
						},
						pagination: props.disabled
							? null
							: {
									count: tentOccupancys.length,
									page,
									rowsPerPage,
									rowsPerPageOptions,
									onPageChange: handleChangePage,
									onRowsPerPageChange: handleChangeRowsPerPage,
							  },
					}}
				/>
			)}
		</FullLayout>
	);
}
