import { assignSection } from 'lib/models/profile';
import { useCallback, useState } from 'react';

function useAssignSection() {
	const [loading, setLoading] = useState<boolean>(false);

	const assign = useCallback(async (username: string, sectionId: string) => {
		setLoading(true);

		try {
			const response = await assignSection(username, sectionId);
			setLoading(false);
			return response;
		} catch (error) {
			console.error(error);
			setLoading(false);
			return null;
		}
	}, []);

	return { loading, assign };
}

export default useAssignSection;
