import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import ShorcutsList from 'components/ShorcutsList';
import usePopulatedShorcuts from 'hooks/usePopulatedShorcuts';
import ShorcutModal from 'components/ShorcutModal';
import { useSearchParams } from 'react-router-dom';
import { FetchShorcutsProps } from 'lib/models/shorcuts';
import SearchToolbar from 'components/SearchToolbar';

export default function Shorcuts() {
	const { t } = useTranslation();
	const title = t('common:shorcuts');

	const defaultShorcut: PopulatedShorcut = {
		userType: 'admin',
		title: '',
		order: 0,
		link: '',
		description: '',
		textBottom: '',
	};

	const [searchParams, setSearchParams] = useSearchParams({
		search: '',
		page: '0',
		rowsPerPage: '25',
	});

	const [search, setSearch] = useState(searchParams.get('search') || '');
	const [page, setPage] = useState(Number(searchParams.get('page') || 0));
	const [rowsPerPage, setRowsPerPage] = useState(Number(searchParams.get('rowsPerPage') || 10));

	const searchProps = useMemo(() => {
		let searchParams: FetchShorcutsProps = { page, rowsPerPage, search };
		return searchParams;
	}, [page, rowsPerPage, search]);

	const { shorcuts, loading, refetch } = usePopulatedShorcuts(searchProps);

	const [showModal, setShowModal] = useState<PopulatedShorcut | null>(null);

	const onEdit = (shorcut: PopulatedShorcut) => {
		setShowModal(shorcut);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function onSearch(value: string) {
		setSearch(value);
	}

	useEffect(() => {
		setSearchParams({ page: `${page}`, rowsPerPage: `${rowsPerPage}`, search: `${search}` });
	}, [setSearchParams, page, rowsPerPage, search]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultShorcut)} disabled={loading} />
			{showModal && <ShorcutModal defaultValue={showModal} onClose={onClose} refetch={refetch} />}

			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Box>
				{!!loading && <LinearProgress />}

				<ShorcutsList
					onEdit={onEdit}
					refetch={refetch}
					shorcuts={shorcuts}
					loading={loading}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					page={page}
					rowsPerPage={rowsPerPage}
				/>
			</Box>
		</ScreenContainer>
	);
}
