import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { Approval as Icon } from '@mui/icons-material';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

export default function Approval({ selected, onSelect, open }: Props) {
	return (
		<ListItem
			button
			component={Link}
			to="/product/approvals"
			selected={selected === MenuItem.Approval}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Approval)}
		>
			<ListItemIcon>
				<Icon />
			</ListItemIcon>
			{open && <ListItemText primary={'Aprobar'} />}
		</ListItem>
	);
}
