import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';
import Documentation from 'components/screens/Documentation';
import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';
import Places from 'components/screens/Places';
import Events from 'components/screens/Events';
import EventEdition from 'components/screens/EventEdition';
import Artists from 'components/screens/Artists';
import ArtistEdition from 'components/screens/ArtistEdition';
import Vehicles from 'components/screens/parking/Vehicles';
import VehiclesEdition from 'components/screens/parking/VehiclesEdition';
import GenerateCode from 'components/screens/parking/GenerateCode';
import Access from 'components/screens/parking/Access';
import AccessDetail from 'components/screens/parking/AccessDetail';
import AppConfig from 'components/screens/AppConfig';
import Faq from 'components/screens/Faq';
import Notifications from 'components/screens/Notifications';
import QRCodes from 'components/screens/parking/QRCodes';
import Merchandising from 'components/screens/Merchandising';
import MerchCategories from 'components/screens/MerchCategories';
import EntryScanner from 'components/screens/parking/EntryScanner';
import ExitScanner from 'components/screens/parking/ExitScanner';
import DailyCodesLoader from 'components/DailyCodesLoader/DailyCodesLoader';
import ScanPlate from 'components/screens/parking/ScanPlate';
import Products from 'components/screens/Products';
import ProductCategories from 'components/screens/ProductCategories';
import Sections from 'components/screens/Sections';
import Candidates from 'components/screens/Candidates';
import AppComments from 'components/screens/AppComments';

import Auth from './Auth';
import Logged from './Logged';
import Print from 'components/screens/Print';
import Shorcuts from 'components/screens/Shorcuts';
import Approval from 'components/screens/Approval';
//import TentOccupancyManager from 'components/screens/TentOccupancyManager/TentOccupancyManager';
//import ParkingOccupancyManager from 'components/screens/ParkingOccupancy';
import OccupancyManager from 'components/screens/OccupancyManager';

function Router() {
	return (
		<BrowserRouter>
			<DailyCodesLoader />
			<Routes>
				<Route key="auth" element={<Logged />}>
					{/* PRIVATE ROUTES */}
					<Route key="route-me" path="/me" element={<Auth element={UsersEdition} rules={['Profile']} />} />
					<Route key="route-users" path="/users" element={<Auth element={Users} rule="UserFull" />} />
					<Route key="route-users" path="/panel/users" element={<Auth element={Users} rule="UserFull" />} />
					<Route
						key="route-users-edit"
						path="/panel/users/:id"
						element={<Auth element={UsersEdition} rule="UserFull" />}
					/>

					<Route
						key="route-users-edit"
						path="/users/:id"
						element={<Auth rules={['UserFull']} element={UsersEdition} rule="UserFull" />}
					/>
					<Route
						key="route-users"
						path="/users"
						element={<Auth rules={['UserFull']} element={Users} rule="UserFull" />}
					/>
					<Route
						key="candidates"
						path="/candidates"
						element={<Auth rules={['CandidatesFull']} element={Candidates} mode="or" />}
					/>
					<Route
						key="app-comments"
						path="/comments"
						element={<Auth rules={['AppCommentsFull']} element={AppComments} mode="or" />}
					/>
					{/* SYSTEM PRIVATE ROUTES */}
					<Route key="route-dashboard" path="/dashboard" element={<Auth element={Dashboard} />} />
					<Route key="route-documentation" path="/documentation" element={<Auth element={Documentation} />} />
					<Route key="route-inbox" path="/inbox" element={<Auth element={Inbox} />} />
					<Route key="route-home" path="/" element={<Auth element={Dashboard} />} />
					<Route key="route-places" path="/places" element={<Auth rule="PlaceFull" element={Places} />} />
					<Route key="route-events" path="/events" element={<Auth rule="EventFull" element={Events} />} />
					<Route
						key="route-event-edition"
						path="/events/:id"
						element={<Auth rule="EventFull" element={EventEdition} />}
					/>
					<Route key="route-artists" path="/artists" element={<Auth rule="ArtistFull" element={Artists} />} />
					<Route
						key="route-artist-edition"
						path="/artists/:id"
						element={<Auth rule="ArtistFull" element={ArtistEdition} />}
					/>
					<Route key="route-places" path="/places" element={<Auth rules="PlacesFull" element={Places} />} />
					<Route key="route-app-config" path="/config" element={<Auth rules="AppConfigFull" element={AppConfig} />} />
					<Route key="route-faq" path="/faq" element={<Auth rules="FaqFull" element={Faq} />} />
					<Route
						key="route-notification"
						path="/notifications"
						element={<Auth rules="NotificationFull" element={Notifications} />}
					/>
					<Route
						key="route-merchandising"
						path="/merchandising"
						element={<Auth rules="MerchFull" element={Merchandising} />}
					/>
					<Route
						key="route-categories"
						path="/categories"
						element={<Auth rules="MerchFull" element={MerchCategories} />}
					/>
					<Route key="route-products" path="/products" element={<Auth rules="BarFull" element={Products} />} />
					<Route
						key="route-product-categories"
						path="/product/categories"
						element={<Auth rules="BarFull" element={ProductCategories} />}
					/>
					<Route key="shorcuts" path="/shorcuts" element={<Auth rules="UserFull" element={Shorcuts} />} />
					<Route
						key="route-product-sections"
						path="/product/sections"
						element={<Auth rules="BarFull" element={Sections} />}
					/>
					<Route
						key="route-product-approval"
						path="/product/approvals"
						element={<Auth rules="BarFull" element={Approval} />}
					/>
					<Route
						key="route-vehicles"
						path="/parking/vehicles"
						element={<Auth rules={['ParkingFull']} mode="or" element={Vehicles} />}
					/>
					<Route
						key="route-vehicles-edit"
						path="/parking/vehicles/:id"
						element={<Auth rules={['ParkingFull']} element={VehiclesEdition} />}
					/>
					<Route
						key="route-access"
						path="/access"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={Access} mode="or" />}
					/>
					<Route
						key="route-access-detail"
						path="/access/:code"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={AccessDetail} mode="or" />}
					/>
					<Route
						key="route-access-detail"
						path="/qr-codes"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={QRCodes} mode="or" />}
					/>
					<Route
						key="route-access-detail"
						path="/print"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={Print} mode="or" />}
					/>
					<Route
						key="route-generate-codes"
						path="/generate"
						element={<Auth rules={['ParkingFull']} element={GenerateCode} mode="or" />}
					/>
					<Route
						key="route-scan-entry"
						path="/scan/entry"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={EntryScanner} mode="or" />}
					/>
					<Route
						key="route-scan-exit"
						path="/scan/exit"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={ExitScanner} mode="or" />}
					/>
					<Route
						key="route-scan-plate"
						path="/scan/plate"
						element={<Auth rules={['ParkingFull', 'ParkingGate']} element={ScanPlate} mode="or" />}
					/>
					{/*<Route
						key="route-tent-occupancy"
						path="/tentOccupancy"
						element={<Auth rules="ParkingFull" element={TentOccupancyManager} />}
	/>*/}
					<Route
						key="route-occupations"
						path="/occupations"
						element={<Auth rules="ParkingFull" element={OccupancyManager} />}
					/>
				</Route>
				{/* SYSTEM PUBLIC ROUTES */}
				<Route key="route-login" path="/login" element={<Login />} />
				{/* <Route key="route-login" path="/login" element={<EntryScanner />} /> */}
				{/* NoMatch */}
				<Route element={<NoMatch />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
