// import { Box, Typography } from '@mui/material';
// import QRCode from 'react-qr-code';
import QR, { QRHeader } from './QR';

interface Props {
	access: Access;
	logoMute: string;
	sponsor: string;
}
const PrintingAccess = ({ access, logoMute, sponsor }: Props) => {
	// const isMultiple = access.qrType === 'multiple';

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				pageBreakAfter: 'always',
				justifyContent: 'center',
				alignItems: 'start',
				boxSizing: 'border-box', // Add this to include border/padding in the box dimension
				margin: 0, // Ensure margin doesn't push out the box
				padding: 0, // Ensure no extra spacing inflates the box
			}}
		>
			<div
				className="zebraTicket"
				style={{
					width: '49.5mm',
					height: '79.5mm',
					maxWidth: '49.5mm',
					backgroundColor: 'none',
					border: '3px solid black',
					borderRadius: '6px',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					pageBreakAfter: 'always',
					boxSizing: 'border-box', // Add this to include border/padding in the box dimension
					margin: 0, // Ensure margin doesn't push out the box
					padding: 0, // Ensure no extra spacing inflates the box
					// marginLeft: '-0.15mm',
				}}
			>
				<div style={{ position: 'relative', width: '100%', height: '100%' }}>
					<QRHeader
						logo={logoMute}
						sponsor={sponsor}
						description={typeof access.type !== 'string' ? access.type?.description : ''}
						id={access.tent}
					/>
					<QR
						value={access.code}
						validDate={
							access.qrType === 'multiple'
								? `${parseDate(access.validDate.from)} a ${parseDate(access.validDate.to)}`
								: `${parseDate(access.validDate.from)}`
						}
						size={148}
					/>
				</div>
			</div>
		</div>
	);
};

export default PrintingAccess;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	}
	return '-';
}

const months: { [key: number]: string } = {
	0: 'ene',
	1: 'feb',
	2: 'mar',
	3: 'abr',
	4: 'may',
	5: 'jun',
	6: 'jul',
	7: 'ago',
	8: 'sep',
	9: 'oct',
	10: 'nov',
	11: 'dic',
};
