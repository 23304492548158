import { Box, Button, CircularProgress, Typography } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import { QRCodeSVG } from 'qrcode.react';
import useApproval from './useApproval';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { currency } from 'lib/helpers';
import { Refresh } from '@mui/icons-material';

export default function Approval() {
	const [showApprovals, setShowApprovals] = useState(false);

	const { approval, loading, refetch, approvals } = useApproval();

	useEffect(() => {
		refetch(false);
	}, [refetch]);

	return (
		<ScreenContainer title={'Aprobar'} goBack={true} sx={{ background: 'black', padding: 4, borderRadius: 2 }}>
			{loading && <CircularProgress />}
			{!loading && !approval && (
				<Box
					sx={{
						padding: 2,
					}}
				>
					<Typography variant="caption">No posees permisos para generar un código de autorización</Typography>
				</Box>
			)}
			{!loading && !!approval && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: 2,
					}}
				>
					<Box
						sx={{
							borderColor: 'black',
							borderWidth: 8,
							borderStyle: 'solid',
							width: 'fit-content',
							padding: 2,
							background: 'white',
						}}
					>
						<QRCodeSVG size={300} version="2" level="L" value={approval} />
					</Box>
					<Typography variant="caption" sx={{ color: 'white' }}>
						Escanear el código QR para aprobar la transacción
					</Typography>
					<Button variant="contained" onClick={() => refetch(true)}>
						Regenerar Código QR
					</Button>
					<Button variant="contained" onClick={() => setShowApprovals(!showApprovals)}>
						{showApprovals ? 'Ocultar aprobaciones' : 'Mostrar aprobaciones'}
					</Button>

					{showApprovals && (
						<Box sx={{ borderRadius: 2, borderWidth: 2, borderStyle: 'solid', borderColor: 'white', padding: 2 }}>
							<span onClick={() => refetch(false)}>
								<Refresh color="primary" sx={{ cursor: 'pointer' }} />
							</span>
							{(approvals ?? []).map((approval) => {
								return (
									<Typography key={approval._id} sx={{ color: 'white' }}>
										{getStatus(approval.status)}: (Fecha: {moment(approval.date).format('DD/MM/YYYY HH:mm:ss')},
										Usuario: {approval.orderBy}, Cliente: {approval.orderTo}, Total: {currency(approval.total)})
									</Typography>
								);
							})}
						</Box>
					)}
				</Box>
			)}
		</ScreenContainer>
	);
}

const getStatus = (status: string): string => {
	switch (status) {
		case 'active':
			return 'Reactivación';
		case 'cancelled':
			return 'Cancelación';
		default:
			return status;
	}
};
