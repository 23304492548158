import { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { currency, enqueueSnackbarError } from 'lib/helpers';
import { createProductCategory, fetchProductCategory, updateProductCategory } from 'lib/models/productCategories';

import useProducts from 'hooks/useProducts';
import { updateProduct } from 'lib/models/products';
import ProductModal from 'components/ProductModal';

type Props = {
	defaultValue: PopulatedProductCategory;
	onClose: () => void;
	refetch: () => void;
	forceClose?: boolean;
};

const search = {
	page: 0,
	// search all products in one page
	rowsPerPage: 50000,
	order: 'asc' as 'asc',
	orderBy: 'title',
};

export default function ProductCategoryModal({ defaultValue, onClose, refetch, forceClose }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [loadingProductCategory, setLoading] = useState(false);
	const [productCategory, setProductCategory] = useState(defaultValue);
	const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

	const [showProductModal, setShowProductModal] = useState<Product | null>(null);

	const [searchValue, setSearch] = useState('');

	const { loading: loadingProducts, products: productsRaw, refetch: refetchProducts } = useProducts(search);

	const loading = loadingProductCategory || loadingProducts;

	const handleDragStart = (index: number) => {
		setDraggingIndex(index);
	};

	const onProductModalClose = (product?: Product | null) => {
		refetch();
		if (product?._id) {
			onAdd(product);
		}
		setShowProductModal(null);
	};

	const products = productsRaw.filter(
		(product) => !productCategory._id || !product.categories.includes(productCategory._id)
	);

	const handleDragOver = (event: DragEvent<HTMLTableRowElement>, index: number) => {
		event.preventDefault(); // Prevent the default behavior (like animation)

		if (draggingIndex === index || draggingIndex === null) return;

		const updatedItems = [...(productCategory.items || [])];
		const draggedItem = updatedItems[draggingIndex];

		// Remove dragged item and insert it at the new index
		updatedItems.splice(draggingIndex, 1);
		updatedItems.splice(index, 0, draggedItem);

		setDraggingIndex(index);
		setProductCategory((current) => ({ ...current, items: updatedItems }));
	};

	const handleDrop = () => {
		setDraggingIndex(null); // Clear dragging state
	};

	const onUpdate = (key: keyof ProductCategory) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setProductCategory({ ...productCategory, [key]: value });
		};
	};

	const toggleVIP = () => {
		setProductCategory({ ...productCategory, vip: !productCategory.vip });
	};

	const refetchProductCategory = async () => {
		const data = await fetchProductCategory(`${productCategory._id}`);
		if (!!data) {
			const items = data.items;
			setProductCategory((current) => ({ ...current, items }));
		}
	};

	const onDelete = async (product: Product) => {
		const removed = await updateProduct({
			...product,
			categories: product.categories.filter((category) => category !== `${productCategory._id}`),
		});
		if (!removed) {
			enqueueSnackbarError('No se pudo quitar el producto de la categoría');
		}
		refetchProductCategory();
		refetch();
		refetchProducts();
	};

	const onAdd = async (product: Product) => {
		const added = await updateProduct({
			...product,
			categories: product.categories.includes(`${productCategory._id}`)
				? product.categories
				: [...product.categories, `${productCategory._id}`],
		});
		if (!added) {
			enqueueSnackbarError('No se pudo agregar el producto a la categoría');
		}
		refetchProductCategory();
		refetch();
		refetchProducts();
	};

	const save = async () => {
		setLoading(true);
		try {
			const data = await (productCategory?._id
				? updateProductCategory(productCategory)
				: createProductCategory(productCategory));
			if (data) {
				setTimeout(() => {
					setProductCategory((current) => ({ ...current, _id: data._id }));
				});
			}
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);

		if (productCategory._id || forceClose) {
			onClose();
		}
	};

	return (
		<Dialog open fullWidth={true} maxWidth="lg">
			{loading && <LinearProgress />}

			{showProductModal && <ProductModal defaultValue={showProductModal} onClose={onProductModalClose} />}

			<DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>{t('merch:merchandising')}</div>
				<div style={{ cursor: 'pointer' }} onClick={onClose}>
					X
				</div>
			</DialogTitle>

			<DialogContent sx={{ minWidth: '30vw' }}>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{productCategory && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={productCategory.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
						/>
						<TextField
							id="title"
							multiline={true}
							label={t('common:description')}
							fullWidth={true}
							value={productCategory.description}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('description')}
							disabled={loading}
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 2,
								ml: 1,
							}}
						>
							<Typography>VIP</Typography>
							<Checkbox
								id="vip"
								value={productCategory.vip}
								onChange={toggleVIP}
								disabled={loading}
								checked={!!productCategory.vip}
							/>
						</Box>

						{productCategory._id && (
							<Box>
								<Typography variant="h6">Productos</Typography>

								<Typography variant="caption" sx={{ color: 'red' }}>
									ADVERTENCIA: Esta pantalla (a diferencia de la pantalla de secciones o productos) guardará la
									selección de los productos automáticamente. guardado manual.
								</Typography>
								<div>
									<Typography variant="caption" sx={{ color: 'red' }}>
										ADVERTENCIA: La modificación del ORDEN de los productos necesita del guardado MANUAL.
									</Typography>
								</div>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left" padding="normal">
												Título
											</TableCell>
											<TableCell align="left" padding="normal">
												Precio
											</TableCell>
											<TableCell align="left" padding="normal">
												Acciones
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{productCategory.items.map((item, index) => {
											return (
												<TableRow
													draggable={true}
													key={`product-table-${item._id}`}
													onDragStart={() => handleDragStart(index)}
													onDragOver={(ev) => handleDragOver(ev, index)}
													onDrop={handleDrop}
												>
													<TableCell align="left" padding="normal">
														<Box onClick={() => setShowProductModal(item)} style={{ cursor: 'pointer' }}>
															{item.title}
														</Box>
													</TableCell>
													<TableCell align="left" padding="normal">
														<Box>{currency(item.price || 0)}</Box>
													</TableCell>
													<TableCell align="left" padding="normal">
														<Checkbox onChange={() => onDelete(item)} checked={true} />
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>

								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
										Seleccionar producto
									</Typography>
									{!!productCategory._id && (
										<Button
											variant="contained"
											color="primary"
											size="large"
											sx={{ m: 1 }}
											onClick={() =>
												setShowProductModal({
													categories: [`${productCategory._id}`],
													price: 0,
													title: '',
												})
											}
											disabled={!productCategory.title}
										>
											Crear producto
										</Button>
									)}
								</Box>

								<TextField
									fullWidth
									value={searchValue}
									label={'Buscar'}
									onChange={(ev) => setSearch(ev.target.value)}
								/>

								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left" padding="normal">
												Título
											</TableCell>
											<TableCell align="left" padding="normal">
												Precio
											</TableCell>
											<TableCell align="left" padding="normal">
												Acciones
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{products.filter(filterProducts(normalizeText(searchValue))).map((item) => {
											return (
												<TableRow key={`product-table-select-${item._id}`}>
													<TableCell align="left" padding="normal">
														<Box onClick={() => setShowProductModal(item)} style={{ cursor: 'pointer' }}>
															{item.title}
														</Box>
													</TableCell>
													<TableCell align="left" padding="normal">
														<Box>{currency(item.price || 0)}</Box>
													</TableCell>
													<TableCell align="left" padding="normal">
														<Checkbox onChange={() => onAdd(item)} checked={false} />
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</Box>
						)}

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!productCategory.title}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}

function normalizeText(text: string): string {
	return text
		.normalize('NFD') // Decomposes characters (e.g., "é" → "é")
		.replace(/[\u0300-\u036f]/g, '') // Removes the diacritic marks while keeping base letters
		.toLowerCase();
}

function filterProducts(searchValue: string) {
	const normalizedSearch = normalizeText(searchValue);

	return (p: Product) => {
		return normalizeText(p.title).includes(normalizedSearch);
	};
}
