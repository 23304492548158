import { TableHead, TableRow, TableCell, TableContainer, Table, TableBody, Box, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

type Props = {
	loading?: boolean;
	editUrl?: string;
	parkingOccupancy: ParkingOccupancy;
	paper?: boolean;
	disabled?: boolean;
	refetch?: () => {};
	customAction?: CustomTooltipProps;
};

export default function ParkingOccupancyTable(props: Props) {
	const { parkingOccupancy } = props;

	const { t } = useTranslation();

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<TableContainer component={Paper} sx={{ mt: 2, mb: 1 }}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ backgroundColor: 'green', color: 'white' }} colSpan={2}>
										{`${t('common:occupancyParking').toUpperCase()}`}:
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component="th" scope="row">
										{`${t('common:total').toUpperCase()}`}{' '}
									</TableCell>
									<TableCell>{parkingOccupancy?.parkedVehicles?.total ?? 0}</TableCell>
								</TableRow>
								{(parkingOccupancy?.parkedVehicles?.byType ?? []).map((row) => (
									<TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row._id}
										</TableCell>
										<TableCell>{row.totalVehiclesInside}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12} md={4}>
					<TableContainer component={Paper} sx={{ mt: 2, mb: 1 }}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ backgroundColor: 'green', color: 'white' }} colSpan={3}>
										{`${t('common:solds').toUpperCase()}`}:{' '}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{`${t('common:type').toUpperCase()}`} </TableCell>
									<TableCell align="center">{`${t('common:quantity').toUpperCase()}`}</TableCell>
									<TableCell align="center">{`${t('common:collected').toUpperCase()}`}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(parkingOccupancy?.parkedVehicles?.byType ?? []).map((row) => (
									<TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row._id}
										</TableCell>
										<TableCell align="center" component="th" scope="row">
											{row.qrVehiclesPaid}
										</TableCell>
										<TableCell align="center" component="th" scope="row">
											{row.totalPaid}
										</TableCell>
									</TableRow>
								))}
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component="th" scope="row" colSpan={2}>
										{`${t('common:total').toUpperCase()}`} {`${t('common:collected').toUpperCase()}`}
									</TableCell>
									<TableCell align="center" component="th" scope="row">
										{parkingOccupancy?.parkedVehicles?.totalPaid}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12} md={4}>
					<TableContainer component={Paper} sx={{ mt: 2, mb: 1 }}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ backgroundColor: 'green', color: 'white' }} colSpan={2}>
										{`${t('common:currentPrices').toUpperCase()}`}:{' '}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(parkingOccupancy?.currentPrices ?? []).map((row) => (
									<TableRow key={row.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row.description}
										</TableCell>
										<TableCell align="center" component="th" scope="row">
											{row.priceStay}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Box>
	);
}
