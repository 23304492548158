import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ScreenContainer from 'components/containers/ScreenContainer';
import ParkingOccupancyTable from 'components/ParkingOccupancyTable';
import useParkingOccupancy from 'hooks/useParkingOccupancy';
import React from 'react';
import TabPanel from 'components/TabPanel/TabPanel';
import useTentOccupancy from 'hooks/useTentOccupancy';
import TentOccupancyTable from 'components/TentOccupancyTable';

function OccupancyManager() {
	const { loading, parkingOccupancy } = useParkingOccupancy();
	const { loading: tentLoading, tentOccupancy } = useTentOccupancy();

	const { t } = useTranslation();
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<ScreenContainer title={`${t('common:occupations')}`}>
			{(!!tentLoading || !!loading) && <LinearProgress />}

			<Tabs value={value} onChange={handleChange}>
				<Tab label={`${t('common:tentOccupancy')}`} />
				<Tab label={`${t('common:occupancyParking')}`} />
			</Tabs>

			<TabPanel value={value} index={0}>
				<TentOccupancyTable tentOccupancy={tentOccupancy} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ParkingOccupancyTable parkingOccupancy={parkingOccupancy} />
			</TabPanel>
		</ScreenContainer>
	);
}

export default OccupancyManager;
