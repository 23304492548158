import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { enqueueSnackbarError } from 'lib/helpers';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { createShorcut, deleteShorcut, updateShorcut } from 'lib/models/shorcuts';
import { UsersTypes } from 'types/global';

type Props = {
	defaultValue: PopulatedShorcut;
	onClose: () => void;
	refetch: () => void;
};

export default function ShorcutModal({ defaultValue, onClose, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [loading, setLoading] = useState(false);
	const [shorcut, setShorcut] = useState(defaultValue);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedShorcut | null>(null);

	const onUpdate = (key: keyof PopulatedShorcut) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			setShorcut({ ...shorcut, [key]: e.target.value });
		};
	};

	const onSelectUpdate = (key: keyof PopulatedShorcut) => (e: SelectChangeEvent) =>
		setShorcut({ ...shorcut, [key]: e.target.value });

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			const deleted = await deleteShorcut(showDeleteConfirmation);
			if (!deleted) enqueueSnackbarError(t('merch:deletionError'));
			else {
				setShorcut({ ...shorcut });
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	//const onDelete = (shorcut: PopulatedShorcut) => {
	//	setShowDeleteConfirmation(shorcut);
	//};

	const save = async () => {
		setLoading(true);
		try {
			await (shorcut?._id ? updateShorcut(shorcut) : createShorcut(shorcut));
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		onClose();
	};

	return (
		<Dialog open>
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			{loading && <LinearProgress />}
			<DialogTitle>{t('common:shorcuts')}</DialogTitle>
			<DialogContent sx={{ minWidth: '30vw' }}>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{shorcut && (
					<FormGroup sx={{ paddingTop: 1 }}>
						{
							<Select
								labelId="type"
								id="type"
								label={t('common:role')}
								fullWidth={true}
								value={shorcut.userType}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onSelectUpdate('userType')}
								disabled={loading}
							>
								<MenuItem value={UsersTypes.admin}>{t('common:role_admin')}</MenuItem>
								<MenuItem value={UsersTypes.parkingAdmin}>{t('common:role_parkingAdmin')}</MenuItem>
								{/* {(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.parkingAdmin) && (
							<MenuItem value={UsersTypes.parkingCashier}>{t('common:role_parkingCashier')}</MenuItem>
						)} */}
								<MenuItem value={UsersTypes.parkingGate}>{t('common:role_parkingGate')}</MenuItem>
								<MenuItem value={UsersTypes.panel}>{t('common:role_panel')}</MenuItem>
								<MenuItem value={UsersTypes.barAdmin}>{t('common:role_barAdmin')}</MenuItem>
								<MenuItem value={UsersTypes.barPanel}>{t('common:role_barPanel')}</MenuItem>
								<MenuItem value={UsersTypes.barCashier}>{t('common:role_barCashier')}</MenuItem>
								{/*<MenuItem value={UsersTypes.user}>{t('common:role_user')}</MenuItem>*/}
							</Select>
						}
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={shorcut.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
						/>
						<TextField
							id="order"
							multiline={false}
							label={t('common:order')}
							fullWidth={true}
							value={shorcut.order}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('order')}
							disabled={loading}
							type="number"
							inputProps={{ min: 1, step: 1 }}
						/>
						<TextField
							id="link"
							multiline={true}
							label={t('common:link')}
							fullWidth={true}
							value={shorcut.link}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('link')}
							disabled={loading}
						/>
						<TextField
							id="description"
							multiline={true}
							label={t('common:description')}
							fullWidth={true}
							value={shorcut.description}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('description')}
							disabled={loading}
						/>
						<TextField
							id="backgroundColor"
							label={t('common:backgroundColor')}
							type="color"
							fullWidth
							value={shorcut.backgroundColor || '#ffffff'}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('backgroundColor')}
							disabled={loading}
						/>
						<TextField
							id="textBottom"
							label={t('common:textBottom')}
							type="text"
							fullWidth
							value={shorcut.textBottom}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('textBottom')}
							disabled={loading}
						/>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 2,
								ml: 1,
							}}
						></Box>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!shorcut.title}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}
