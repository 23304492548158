import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delete, Edit, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, LinearProgress, Typography } from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';
import { deleteProductCategory } from 'lib/models/productCategories';

import ConfirmationDialog from 'components/ConfirmationDialog';
import PaperContainer from 'components/containers/PaperContainer';
import { price } from 'lib/numbers';
import ProductModal from 'components/ProductModal';

type Props = {
	loading: boolean;
	data: PopulatedProductCategory[];
	onEdit: (productCategory: PopulatedProductCategory) => void;
	refetch: () => void;
};

export default function ProductCategoriesList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [showProductModal, setShowProductModal] = useState<Product | null>(null);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedProductCategory | null>(null);
	const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});

	const onProductModalClose = () => {
		refetch();
		setShowProductModal(null);
	};

	const toggleExpand = (id: string) => {
		setExpandedCategories((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const onDelete = (productCategory: PopulatedProductCategory) => {
		setShowDeleteConfirmation(productCategory);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			if (showDeleteConfirmation.items.length > 0) {
				setShowDeleteConfirmation(null);
				return enqueueSnackbarError(t('product:errorHasItems'));
			}
			const deleted = await deleteProductCategory(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	return (
		<Box>
			{showProductModal && <ProductModal defaultValue={showProductModal} onClose={onProductModalClose} />}

			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			<Box>
				{data.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{data.map((el) => {
					const isExpanded = expandedCategories[`${el._id}`] ?? false;

					return (
						<PaperContainer
							key={el._id}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								mb: 2,
								position: 'relative',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									cursor: 'pointer',
								}}
								onClick={() => toggleExpand(`${el._id}`)}
							>
								<Typography variant="h6">
									<strong style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
										{el.title} {!!el.vip && <Typography sx={{ textDecoration: 'underline' }}> VIP</Typography>}
									</strong>
								</Typography>

								<IconButton>
									<ExpandMore
										sx={{
											transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
											transition: 'transform 0.3s',
										}}
									/>
								</IconButton>
							</Box>

							<Collapse in={isExpanded}>
								<Box sx={{ p: 2 }}>
									<Typography sx={{ mb: 2 }}>{el.description}</Typography>
									{el.items.map((item) => (
										<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<Typography key={item.title} sx={{ mb: 2 }}>
												{item.title}
											</Typography>
											<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
												<Typography key={item.title} sx={{ mb: 2 }}>
													{price(item.price ?? 0)}
												</Typography>
												<Edit sx={{ cursor: 'pointer' }} onClick={() => setShowProductModal(item)} color="primary" />
											</Box>
										</Box>
									))}
								</Box>
							</Collapse>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									gap: 1,
									position: 'absolute',
									top: 24,
									right: 64,
								}}
							>
								<Edit sx={{ cursor: 'pointer' }} onClick={() => onEdit(el)} color="primary" />
								<Delete sx={{ cursor: 'pointer' }} onClick={() => onDelete(el)} color="secondary" />
							</Box>
						</PaperContainer>
					);
				})}
			</Box>
		</Box>
	);
}
