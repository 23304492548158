import { API_DOMAIN, ORDERS_URL, PRODUCTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';
import { z } from 'zod';

export const productStatus: ProductStatus[] = ['active', 'draft'];

export type FetchProductSearchProps = {
	search?: string | null;
};

export type FetchProductProps = FetchProductSearchProps & PaginationProps;

export const zProduct = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	description: z.optional(z.string()),
	price: z.number().nullable(),
	promotionPrice: z.optional(z.number().nullable()),
	categories: z.array(z.string()),
	mixer: z.optional(z.object({ optional: z.boolean(), elements: z.array(z.string()) })),
});

export async function fetchProducts(props: FetchProductProps): Promise<Product[]> {
	try {
		const sort: Sort = {
			[props.orderBy || 'title']: props.order || 'asc',
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${PRODUCTS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zProduct).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createProduct(product: Product): Promise<Product | null> {
	const response = await api.post(`${API_DOMAIN}${PRODUCTS_URL}`, product);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zProduct.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export type Approval = { _id: string; date: Date; status: string; orderBy: string; orderTo: string; total: number };

export async function approve(
	regenerate: boolean
): Promise<{ adminKey?: string | null; approvals: Approval[] } | null> {
	try {
		const response = await api.get<{ adminKey?: string | null; approvals: Approval[] }>(
			`${API_DOMAIN}${ORDERS_URL}/approval?regenerate=${regenerate ? 'yes' : 'no'}`
		);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data || null;
	} catch (error) {
		console.error(error);
	}
	return null;
}

export async function updateProduct(product: Product): Promise<Product | null> {
	const response = await api.patch(`${API_DOMAIN}${PRODUCTS_URL}/${product._id}`, product);

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	const data = zProduct.safeParse(response.data);

	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteProduct(product: Product): Promise<boolean | null> {
	const response = await api.delete(`${API_DOMAIN}${PRODUCTS_URL}/${product._id}`);

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response?.data?._id === product._id;
}
