import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	handleClose: () => void;
	onVerify: (code: string, serverIsDown: boolean) => Promise<void>;
	isDown: boolean;
};

function ManuallyExit({ open, handleClose, onVerify, isDown }: Props) {
	const { t } = useTranslation();

	const [plate, setPlate] = useState<string>('');

	const handlePlate = (plate: string) => {
		setPlate(plate);
	};

	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">{'Ingresar código'}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					autoComplete="off"
					value={plate}
					onChange={(e) => handlePlate(e.target.value.toUpperCase())}
					inputProps={{
						style: { textTransform: 'uppercase', textAlign: 'center' },
						maxLength: 8,
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						setPlate('');
					}}
					color="error"
				>
					{t('common:cancel')}
				</Button>
				<Button
					onClick={() => {
						onVerify(plate, isDown);
						setPlate('');
					}}
					color="primary"
				>
					{t('common:confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ManuallyExit;
