import { removeSection } from 'lib/models/profile';
import { useCallback, useState } from 'react';

function useRemoveSection() {
	const [loading, setLoading] = useState<boolean>(false);

	const remove = useCallback(async (username: string) => {
		setLoading(true);

		try {
			const response = await removeSection(username);
			setLoading(false);
			return response;
		} catch (error) {
			console.error(error);
			setLoading(false);
			return null;
		}
	}, []);

	return { loading, remove };
}

export default useRemoveSection;
