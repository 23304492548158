import { fetchUserSection } from 'lib/models/profile';
import { useCallback, useEffect, useState } from 'react';

function useUserSection(username: string) {
	const [loading, setLoading] = useState(true);
	const [section, setSection] = useState<ProductSection | null>(null);

	const refetch = useCallback(async () => {
		setLoading(true);

		try {
			const res = await fetchUserSection(username);

			setSection(res);
		} catch (error) {
			console.error(error);
		}

		setLoading(false);
	}, [username]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, section, refetch };
}

export default useUserSection;
