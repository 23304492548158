const merch = {
	products: 'Productos',
	errorHasItems: 'El elemento tiene items cargados, por favor quítelos antes de continuar',
	deletionError: 'Error al eliminar',
	productCategories: 'Cat. Productos',
	updateError: 'Error al actualizar producto',
	activeDescription: '¿Está seguro que desea publicar el producto seleccionado?',
	activeError: 'El producto seleccionado no pudo ser publicado',
	draftTitle: 'Despublicar producto',
	draftDescription: '¿Está seguro que desea despublicar el producto seleccionado?',
	draftError: 'El producto seleccionado no pudo ser despublicar',
	sections: 'Pto. Venta',
	allowPromotionPrice: 'Permitir promociones',
	dontAllowPromotionPrice: 'No permitir promociones',
} as const;

export default merch;
