import { useState } from 'react';

import {
	Box,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';

import { enqueueSnackbarError } from 'lib/helpers';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import usePopulatedSections from 'hooks/usePopulatedSections';
import useAssignSection from 'hooks/useAssignSection';
import useUserSection from 'hooks/useUserSection';
import useRemoveSection from 'hooks/useRemoveSection';

type Props = {
	user: User;
	onClose: () => void;
};

export default function SelectUserSectionModal({ user, onClose }: Props) {
	const successSnackbar = useSuccessSnackbar();

	const { loading: loadingUser, section: selected } = useUserSection(user.username);

	const { assign, loading: loadingAssign } = useAssignSection();

	const { remove, loading: loadingRemove } = useRemoveSection();

	const { loading: loadingSections, sections } = usePopulatedSections();

	const [searchValue, setSearch] = useState('');

	const loading = loadingAssign || loadingSections || loadingUser || loadingRemove;

	const selectSection = async (section: PopulatedProductSection) => {
		const success = await (selected ? remove(user.username) : assign(user.username, `${section._id}`));
		if (success) {
			successSnackbar();
		} else {
			enqueueSnackbarError('Hubo un error');
		}
		onClose();
	};

	return (
		<Dialog open fullWidth={true} maxWidth="lg">
			{loading && <LinearProgress />}

			<DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div>{!!selected ? 'Remover' : 'Seleccionar'} punto de venta</div>
				<div style={{ cursor: 'pointer' }} onClick={() => onClose()}>
					X
				</div>
			</DialogTitle>

			<DialogContent>
				{loading && <LinearProgress sx={{ mb: 3 }} />}

				{loading && <CircularProgress />}

				{!selected && (
					<TextField
						fullWidth
						value={searchValue}
						label={'Buscar'}
						onChange={(ev) => setSearch(ev.target.value)}
						sx={{ mt: 2 }}
					/>
				)}

				{!loadingAssign && (
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									Título
								</TableCell>
								<TableCell align="left" padding="normal">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sections
								.filter((section) => !selected || section._id === selected._id)
								.filter(filterSections(normalizeText(searchValue)))
								.map((section) => {
									return (
										<TableRow key={`section-table-${section._id}`}>
											<TableCell align="left" padding="normal">
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>{section.title}</Box>
											</TableCell>
											<TableCell align="left" padding="normal">
												<Checkbox
													onChange={() => {
														selectSection(section);
													}}
													checked={!!selected && selected._id === section._id}
												/>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				)}
			</DialogContent>
		</Dialog>
	);
}

function normalizeText(text: string): string {
	return text
		.normalize('NFD') // Decomposes characters (e.g., "é" → "é")
		.replace(/[\u0300-\u036f]/g, '') // Removes the diacritic marks while keeping base letters
		.toLowerCase();
}

function filterSections(searchValue: string) {
	const normalizedSearch = normalizeText(searchValue);

	return (p: PopulatedProductSection) => {
		return normalizeText(p.title).includes(normalizedSearch);
	};
}
