import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';

interface Props {
	isOpen: boolean;
	count: number;
	onConfirm: () => Promise<{ success: true; count: number } | void>;
	onClose: () => void;
}
export const ResetDayModal = ({ isOpen, count, onConfirm, onClose }: Props) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [movementCount, setMovementCount] = useState(0);

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
			{loading ? (
				<>
					<DialogTitle align="center">En proceso...</DialogTitle>
					<DialogContent
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress />
					</DialogContent>
				</>
			) : success ? (
				<>
					<DialogTitle>Proceso finalizado exitosamente.</DialogTitle>
					<DialogContent>
						<Typography>
							Fueron generados egresos para los {movementCount} ingresos que no tenían su correspondiente egreso.
						</Typography>
						<Typography>Ahora el parking se encuentra vacío.</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								onClose();
								setTimeout(() => {
									setSuccess(false);
									setMovementCount(0);
								}, 2000);
							}}
							color="primary"
						>
							Cerrar
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<DialogTitle>Confirmar reinicio del día</DialogTitle>
					<DialogContent>
						<Typography>
							Esta acción generará egresos para todo ingreso que no tenga su correspondiente egreso.
						</Typography>
						<Typography>
							En total se verán afectados <strong>{count}</strong> accesos.
						</Typography>
						<Typography>Esta operación no se puede deshacer. ¿Estás seguro de que deseas continuar?</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="error">
							Cancelar
						</Button>
						<Button
							onClick={async () => {
								try {
									setLoading(true);
									const result = await onConfirm();

									if (result) {
										setSuccess(result.success);
										setMovementCount(result.count);
									}
								} catch (error) {
									onClose();
								} finally {
									setLoading(false);
								}
							}}
							color="primary"
						>
							Confirmar
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
