import { Add, Edit } from '@mui/icons-material';
import { Button, CircularProgress, Typography } from '@mui/material';

import useUserSection from 'hooks/useUserSection';
import { UsersTypes } from 'types/global';

type Props = {
	user: User;
	onPress: (user: User) => void;
};

function SectionButton({ user, onPress }: Props) {
	const { loading, section } = useUserSection(user.username);

	return ([UsersTypes.barAdmin, UsersTypes.barPanel, UsersTypes.barCashier] as string[]).includes(user.type) ? (
		<Button
			onClick={() => onPress(user)}
			variant="outlined"
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 1,
				maxWidth: 150,
			}}
			color={section ? 'secondary' : 'primary'}
		>
			{loading && <CircularProgress />}
			{!loading && !section && <Typography variant="caption">Pto. Venta</Typography>}
			{!loading && !section && <Add sx={{ width: 20 }} />}
			{!loading && !!section && (
				<Typography variant="caption" noWrap>
					{section.title}
				</Typography>
			)}
			{!loading && !!section && <Edit sx={{ width: 20 }} />}
		</Button>
	) : null;
}

export default SectionButton;
