import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QRCodeSVG } from 'qrcode.react';
import style from '../AccessTickets.module.css';

type Props = {
	value: string;
	validDate?: string;
	size?: number;
};

function QR({ size, value, validDate }: Props) {
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<div className={style.qrContainer}>
					<div
						style={{
							padding: '8px',
							backgroundColor: 'white',
							border: '6px solid black',
						}}
					>
						<QRCodeSVG size={size ?? 120} version="2" level="L" value={value} />
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							lineHeight: '1.2',
						}}
					>
						<Typography variant="caption" lineHeight={1.4} fontSize={14}>
							{value}
						</Typography>
						<Typography fontSize={8} lineHeight={1.2} variant="body1" textAlign="center">
							{validDate ?? ''}
						</Typography>
					</div>
				</div>
			</Box>
			{/* <div style={{ display: 'flex', flex: 1 }}></div> */}
			<Box sx={{ paddingX: '2px', paddingBottom: '3px' }}>
				<Typography fontSize={7} variant="body2">
					Ticket no válido como factura
				</Typography>
				<Typography fontSize={6} variant="body2">
					El establecimiento no se responsabiliza por roturas, faltantes, robos o hurtos.
				</Typography>
				<Typography fontSize={5} variant="body2">
					La seguridad de los vehículos es responsabilidad de sus titulares.
				</Typography>
				<Typography fontSize={5} variant="body2">
					La casa se reserva el derecho de admisión y permanencia.
				</Typography>
				<Typography fontSize={5} variant="body2">
					El estacionamiento no incluye el uso de baños.
				</Typography>
			</Box>
		</>
	);
}

export default QR;
