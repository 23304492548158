export enum UsersTypes {
	admin = 'admin',
	parkingAdmin = 'parkingAdmin',
	parkingCashier = 'parkingCashier',
	parkingGate = 'parkingGate',
	barAdmin = 'barAdmin',
	barPanel = 'barPanel',
	barCashier = 'barCashier',
	panel = 'panel',
	user = 'user',
}

export enum MenuItem {
	Dashboard = 0,
	Inbox = 1,
	Starred = 2,
	PanelUsers = 3,
	User = 4,
	Documentation = 5,
	Places = 6,
	Users = 7,
	Events = 8,
	Artists = 9,
	Gate = 10,
	Cashier = 11,
	Vehicles = 12,
	Access = 13,
	GenerateCode = 14,
	ScanCode = 15,
	AppConfig = 16,
	Faq = 17,
	Notifications = 18,
	QRCodes = 19,
	Merchandising = 20,
	MerchCategories = 21,
	ScanAndAssignPlate = 22,
	Products = 23,
	ProductCategories = 24,
	Sections = 25,
	Candidates = 26,
	AppComment = 27,
	Shorcuts = 28,
	Approval = 29,
	TentOccupancy = 30,
	ParkingOccupancy = 31,
}
