import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	access?: Access;
	handleClose: () => void;
	errorType?: 'alreadyUsed' | 'outOfDate' | 'fullSlots' | 'unexpected';
};

function InvalidScanModal({ open, access, handleClose, errorType = 'unexpected' }: Props) {
	const { t } = useTranslation();

	const errorMessages = {
		alreadyUsed: t('access:alreadyUsed'),
		outOfDate: t('access:outOfDate'),
		fullSlots: `Máximo de vehículos alcanzado (${access?.vehiclesInside}/${access?.slots})`,
		unexpected: 'Error inesperado',
	};

	return (
		<Dialog open={!!open}>
			<DialogTitle align="center" sx={{ color: 'red' }}>
				{errorMessages[errorType]}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{`${t('access:code')}: : ${access?.code}`}</DialogContentText>
				<DialogContentText>
					{access?.plate?.length && access?.plate?.length > 1 ? 'Patentes' : 'Patente'}
					{`: ${access?.plate.length ? access.plate : '-'}`}
				</DialogContentText>
				<DialogContentText>
					{access?.qrType === 'simple' ? t('common:date') : t('access:start_date')}
					{`: ${!!access?.validDate.from ? parseDate(access?.validDate.from) : parseDate(access?.createdAt)}`}
				</DialogContentText>
				{access?.qrType === 'multiple' && access?.validDate.to && (
					<DialogContentText>
						{t('access:end_date')}: {parseDate(access?.validDate.to)}
					</DialogContentText>
				)}
				{access?.tent && <DialogContentText>ID: {access.tent}</DialogContentText>}
				{access && access.qrType === 'simple' && errorType === 'alreadyUsed' && (
					<>
						<DialogContentText>Horario de ingreso: {parseTime(access.movements[0].date)}</DialogContentText>
						{/* <DialogContentText>Horario de egreso: {parseTime(access.movements[1].date)}</DialogContentText> */}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="error">
					{t('common:accept')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default InvalidScanModal;

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
	return parsedDate;
}

const parseTime = (isoString: string) => {
	const date = new Date(isoString);

	const hours = date.getHours();
	const minutes = date.getMinutes();

	const formattedMinutes = minutes.toString().padStart(2, '0');

	return `${hours}:${formattedMinutes}`;
};
