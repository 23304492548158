import { FetchCandidatesProps } from 'lib/models/candidates';
import { fetchPopulatedShorcuts } from 'lib/models/shorcuts';
import { useCallback, useEffect, useState } from 'react';

function usePopulatedShorcuts(props: FetchCandidatesProps) {
	const [loading, setLoading] = useState<boolean>(false);

	const [shorcuts, setShorcuts] = useState<PaginateSource<PopulatedShorcut>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchPopulatedShorcuts(props);
		setShorcuts(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, shorcuts, refetch };
}

export default usePopulatedShorcuts;
