import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { downloadSyncErrors } from 'lib/models/access';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	code: string;
	open: string | null;
	route?: RouteType;
	handleClose: () => void;
	result: { code: string; description: string; error: boolean }[];
	setResult: (result: []) => void;
	onSave: (plate: string) => void;
	offlineSuccess: string[];
	offlineErrors: { code: string; message: string; type: string }[];
};

function OfflineScanning({
	code,
	route,
	open,
	handleClose,
	result,
	setResult,
	onSave,
	offlineErrors,
	offlineSuccess,
}: Props) {
	const { t } = useTranslation();

	const [plate, setPlate] = useState<string>('');

	const totalScans = offlineErrors.length + offlineSuccess.length;

	const downloadCsv = async () => {
		try {
			const response = await downloadSyncErrors(offlineErrors);

			const blob = new Blob([response], { type: 'text/csv' });

			const url = window.URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = url;
			a.download = 'Errores.csv';
			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.log('Hubo un error al descargar el .csv');
		}
	};

	// const errors = result?.filter((obj) => obj.error === true);

	// function enqueueScan() {
	// 	if (!route || typeof route !== 'string') return;

	// 	let savedQueue: StoredScan[] = JSON.parse(localStorage.getItem('scanQueue') || '[]');
	// 	if (!Array.isArray(savedQueue)) savedQueue = [];
	// 	const item: StoredScan = { code, type: route, date: moment().toISOString(), plate };
	// 	savedQueue.push(item);
	// 	const stringQueue = JSON.stringify(savedQueue);
	// 	localStorage.setItem('scanQueue', stringQueue);

	// 	enqueueSnackbarSuccess(t('access:savedInLocalStorage'));

	// 	return handleClose();
	// }

	return (
		<Dialog open={!!open}>
			{totalScans === 0 ? (
				<>
					<DialogTitle sx={{ paddingTop: '.5rem', paddingBottom: 0 }} textAlign="center">
						{t('access:offlineScanning')}
					</DialogTitle>
					<DialogContent>
						<DialogContentText textAlign="center" marginY=".5rem">
							{t('access:code')}: {code}
						</DialogContentText>
						<DialogContentText textAlign="center" marginBottom=".5rem">
							{`${t('access:assignPlate')} (${t('access:optional')})`}
						</DialogContentText>
						<TextField
							autoFocus
							autoComplete="off"
							value={plate}
							onChange={(e) => setPlate(e.target.value.toUpperCase())}
							inputProps={{
								style: { textTransform: 'uppercase', textAlign: 'center' },
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							onClick={() => {
								handleClose();
								setResult([]);
							}}
						>
							{t('common:cancel')}
						</Button>
						<Button color="primary" onClick={() => onSave(plate)}>
							{t('common:save')}
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<DialogTitle textAlign="center">Movimientos sincronizados: {totalScans}</DialogTitle>
					<Typography variant="h6" textAlign="center">
						{t('access:errors')}: {offlineErrors?.length}
					</Typography>
					<DialogContent>
						{offlineErrors?.map((r, i) => (
							<DialogContentText textAlign={'left'} color={'red'} key={i}>
								{r.code}: {r.message}.
							</DialogContentText>
						))}
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={async () => {
								if (offlineErrors.length) {
									await downloadCsv();
								}

								handleClose();
							}}
						>
							Descargar CSV
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}

export default OfflineScanning;
