import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ListSubheader, List } from '@mui/material';

import { DRAWER_WIDTH } from 'config/constants';

import useDrawer from '../hooks/useDrawer';
import useDrawerActions from '../hooks/useDrawerActions';

import DashboardItem from './MenuItems/Dashboard';
import UserItem from './MenuItems/User';
import PanelUsersItem from './MenuItems/PanelUsers';
import UsersItem from './MenuItems/Users';
import PlacesItem from './MenuItems/Places';
import DocumentationItem from './MenuItems/Documentation';
import EventItem from './MenuItems/Events';
import ArtistItem from './MenuItems/Artists';
import AccessTypes from './MenuItems/AccessTypes';
import ScanEntry from './MenuItems/ScanEntry';
import ScanExit from './MenuItems/ScanExit';
import GenerateCode from './MenuItems/GenerateCode';
import Access from './MenuItems/Access';
import AppConfig from './MenuItems/AppConfig';
import Faq from './MenuItems/Faq';
import Notifications from './MenuItems/Notifications';
import QRCodes from './MenuItems/QRCodes';
import Candidates from './MenuItems/Candidates';
import AppComments from './MenuItems/AppComments';

import AuthorizedComponent from 'components/AuthorizedComponent';
import Merchandising from './MenuItems/Merchandising';
import MerchCategories from './MenuItems/MerchCategories';
import Product from './MenuItems/Products';
import ProductCategories from './MenuItems/ProductCategories';
import Sections from './MenuItems/Sections';
import ShorcutsItem from './MenuItems/Shorcuts';
import { UsersTypes } from 'types/global';
import Approval from './MenuItems/Approval';
//import TentOccupancyManager from './MenuItems/TentOccupancyManager';
import ParkingOccupancy from './MenuItems/ParkingOccupancy';

type Props = {
	open?: boolean;
};

function Menu({ open }: Props) {
	const { t } = useTranslation();

	const { selectedItem } = useDrawer();

	const { setSelectedItem } = useDrawerActions();

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedItem(index);
	};

	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			sx={{
				width: '100%',
				maxWidth: DRAWER_WIDTH,
				backgroundColor: 'background.paper',
				paddingTop: 4,
			}}
		>
			{open && (
				<ListSubheader component="div" id="nested-list-subheader-general">
					{t('common:general')}
				</ListSubheader>
			)}

			{!open && <div style={{ marginTop: '64px' }} />}

			<AuthorizedComponent
				element={() => <DashboardItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>

			<AuthorizedComponent
				element={() => <PanelUsersItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="UserFull"
			/>

			<AuthorizedComponent
				element={() => <ShorcutsItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="UserFull"
			/>

			<AuthorizedComponent
				element={() => <Candidates open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="CandidatesFull"
			/>

			<AuthorizedComponent
				element={() => <Divider />}
				rules={['UserList', 'PlaceFull', 'EventFull', 'ArtistFull']}
				mode="or"
			/>

			{open && (
				<AuthorizedComponent
					element={() => (
						<ListSubheader component="div" id="nested-list-subheader-app">
							{t('common:app')}
						</ListSubheader>
					)}
					rules={['PlaceFull', 'EventFull', 'ArtistFull']}
					mode="or"
				/>
			)}

			<AuthorizedComponent
				element={() => <UsersItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				validator={(type) => ([UsersTypes.admin] as UserType[]).includes(type)}
			/>

			<AuthorizedComponent
				element={() => <AppComments open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				validator={(type) => ([UsersTypes.admin] as UserType[]).includes(type)}
			/>

			<AuthorizedComponent
				element={() => <AppConfig open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="AppConfigFull"
			/>

			<AuthorizedComponent
				element={() => <Faq open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="FaqFull"
			/>

			<AuthorizedComponent
				element={() => <PlacesItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="PlaceFull"
			/>

			<AuthorizedComponent
				element={() => <EventItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="EventFull"
			/>

			<AuthorizedComponent
				element={() => <ArtistItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="ArtistFull"
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <Notifications open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="NotificationFull"
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <Merchandising open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="MerchFull"
				mode="or"
			/>
			<AuthorizedComponent
				element={() => <MerchCategories open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="MerchFull"
				mode="or"
			/>

			{/* BAR */}
			<AuthorizedComponent element={() => <Divider />} rule="BarFull" />
			{open && (
				<AuthorizedComponent
					element={() => (
						<ListSubheader component="div" id="nested-list-subheader-products">
							Productos/Servicios
						</ListSubheader>
					)}
					rule="BarFull"
				/>
			)}
			<AuthorizedComponent
				element={() => <Product open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="BarFull"
			/>
			<AuthorizedComponent
				element={() => <ProductCategories open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="BarFull"
			/>
			<AuthorizedComponent
				element={() => <Sections open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="BarFull"
			/>
			<AuthorizedComponent
				element={() => <Approval open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="BarFull"
			/>

			{/* PARKING */}
			<AuthorizedComponent element={() => <Divider />} rules={['ParkingFull', 'ParkingGate']} mode="or" />

			{open && (
				<AuthorizedComponent
					element={() => (
						<ListSubheader component="div" id="nested-list-subheader-parking">
							{t('common:parking')}
						</ListSubheader>
					)}
					rules={['ParkingFull', 'ParkingGate']}
					mode="or"
				/>
			)}
			<AuthorizedComponent
				element={() => <QRCodes open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull', 'ParkingGate']}
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <AccessTypes open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull']}
				mode="or"
			/>
			<AuthorizedComponent
				element={() => <Access open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull', 'ParkingGate']}
				mode="or"
			/>
			<AuthorizedComponent
				element={() => <GenerateCode open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull']}
				mode="or"
			/>
			<AuthorizedComponent
				element={() => <ScanEntry open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull', 'ParkingGate']}
				mode="or"
			/>
			<AuthorizedComponent
				element={() => <ScanExit open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['ParkingFull', 'ParkingGate']}
				mode="or"
			/>

			{/*<AuthorizedComponent
				element={() => <TentOccupancyManager open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="ParkingFull"
					/>*/}

			<AuthorizedComponent
				element={() => <ParkingOccupancy open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="ParkingFull"
			/>

			<Divider />

			<AuthorizedComponent
				element={() => <UserItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule={['Profile']}
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <DocumentationItem open={open} selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>
		</List>
	);
}

export default Menu;
