import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, Button, Tooltip, Typography, keyframes } from '@mui/material';
import { InvalidScanModal, ManuallyExit, OfflineScanning, SuccessfulScan } from 'components/Modals';
import QRReader from 'components/QRReader/QRReader';
import { AccessModals } from 'enums/access';
import useServerDown from 'hooks/useServerDown';
import useUser from 'hooks/useUser';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { OfflineScan, saveExit, syncScans, verifyCodeDateRangeOnExit } from 'lib/models/access';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const spin = keyframes({
	'100%': {
		transform: 'rotate(360deg)',
	},
});

function ExitScanner() {
	const { t } = useTranslation();
	const isDown = useServerDown();
	const { user } = useUser();

	const [scanQueue, setScanQueue] = useState<OfflineScan[]>(JSON.parse(localStorage.getItem('scanQueue') || '[]'));

	const [isSyncing, setIsSyncing] = useState(false);

	const found = useRef('');
	const [access, setAccess] = useState<Access | undefined>();
	const [openModal, setOpenModal] = useState<string | null>(null);
	const [flash, setFlash] = useState<string | null>(null);
	const [code, setCode] = useState<string>('');
	const [offlineResults, setOfflineResults] = useState([]);
	const [isOut, setIsOut] = useState<'date' | 'hour' | undefined>(undefined);
	const [invalidScanError, setInvalidScanError] = useState<'alreadyUsed'>();

	const [activeCamera, setActiveCamera] = useState(false);
	const [isEnterPressed, setIsEnterPressed] = useState(false);

	const [offlineSuccess, setOfflineSuccess] = useState<string[]>([]);
	const [offlineErrors, setOfflineErrors] = useState<{ code: string; message: string; type: string }[]>([]);

	const closeAll = () => {
		setOpenModal(null);
		found.current = '';
		setCode('');
		setAccess(undefined);
		setInvalidScanError(undefined);
	};

	const handleFlash = (color: string) => {
		setFlash(color);

		setTimeout(() => {
			setFlash(null);
		}, 500);
	};

	const verifyExit = useCallback(
		async (code: string, serverIsDown: boolean) => {
			if (!!code && !found.current) {
				found.current = code;
				if (isDown) {
					setCode(code);
					return setOpenModal(AccessModals.OfflineScanning);
				}

				const response = await verifyCodeDateRangeOnExit(code);

				if (typeof response === 'string') {
					enqueueSnackbarError(t(`${response}`));
					handleFlash('red');
					setOpenModal('');
					setTimeout(() => {
						closeAll();
					}, 1000);
					return;
				}

				if ('offline' in response || serverIsDown) {
					setOpenModal(null);
					if (user) {
						const offlineQueue = scanQueue;
						const userId = user._id;

						const newScan: OfflineScan = {
							code,
							user: userId,
							date: new Date().toISOString(),
							type: 'entry' as 'entry',
						};

						offlineQueue.push(newScan);

						localStorage.setItem('scanQueue', JSON.stringify(offlineQueue));
						setScanQueue(offlineQueue);
					}
					closeAll();
					return;
				}

				const { access, alreadyUsed, outOfDate, outOfHour } = response;
				setAccess(access);

				if (alreadyUsed) {
					setInvalidScanError('alreadyUsed');
					handleFlash('red');
					enqueueSnackbarError('QR ya utilizado');
					return setOpenModal(AccessModals.SuccessfulScan);
				}

				if (access.vehiclesInside === 0) {
					enqueueSnackbarError(t(`access:accessWithoutPrevEntry`));
					handleFlash('red');
					setOpenModal('');
					setTimeout(() => {
						closeAll();
					}, 1000);

					return;
				}

				if (outOfDate || outOfHour) {
					setIsOut(outOfDate ? 'date' : 'hour');
					handleFlash('yellow');
					return setOpenModal(AccessModals.SuccessfulScan);
				}

				handleFlash('green');
				return setOpenModal(AccessModals.SuccessfulScan);
			}
		},
		[isDown, scanQueue, t, user]
	);

	const onSaveExit = async ({ code, plate }: { code?: string; plate?: string }) => {
		try {
			if (plate || code) {
				await saveExit({ code, plate });
				if (!access) {
					handleFlash('green');
				}
				enqueueSnackbarSuccess(t('access:exitSuccess'));
			}
		} catch (error) {
			handleFlash('red');
			enqueueSnackbarError(error);
		}
		closeAll();
	};

	const modalProps = {
		open: openModal,
		handleClose: closeAll,
		access,
	};

	const onSaveOfflineExit = async (plate?: string) => {
		if (user) {
			const offlineQueue = scanQueue;
			const userId = user._id;
			const newScan: OfflineScan = {
				code,
				plate,
				user: userId,
				date: new Date().toISOString(),
				type: 'exit',
			};
			offlineQueue.push(newScan);
			localStorage.setItem('scanQueue', JSON.stringify(offlineQueue));
			setScanQueue(offlineQueue);
		}
		closeAll();
	};

	const modals: Record<string, React.ReactNode> = {
		OfflineScanning: (
			<OfflineScanning
				{...modalProps}
				code={code}
				route={'exit'}
				result={offlineResults}
				setResult={setOfflineResults}
				onSave={onSaveOfflineExit}
				offlineErrors={offlineErrors}
				offlineSuccess={offlineSuccess}
			/>
		),
		ManuallyExit: <ManuallyExit {...modalProps} onVerify={verifyExit} isDown={isDown} />,
		SuccessfulScan: <SuccessfulScan {...modalProps} onSave={onSaveExit} isEntry={false} isOut={isOut} />,
		InvalidScan: <InvalidScanModal {...modalProps} errorType={invalidScanError} />,
		Loading: <></>,
	};

	const syncStoredScan = async () => {
		try {
			setIsSyncing(true);
			const { errors, successScans } = await syncScans();

			console.log(errors);

			setOpenModal(AccessModals.OfflineScanning);
			setOfflineErrors(errors);
			setOfflineSuccess(successScans);

			localStorage.setItem('scanQueue', '[]');

			// if (!response) return enqueueSnackbarSuccess(t('access:errorOnSync'));

			// if (response.length === 0) return enqueueSnackbarSuccess(t('access:noEnqueuedScans'));

			// setOfflineResults(response);
			if (!errors.length) {
				enqueueSnackbarSuccess(t(`common:successSync`));
			} else {
				enqueueSnackbarError(t(`common:failSync`));
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbarError(t(`common:errorSync`));
		} finally {
			setIsSyncing(false);
		}
	};
	useEffect(() => {
		const handleKeyPress = async (event: KeyboardEvent) => {
			const char = event.key;
			if (!activeCamera && !openModal) {
				if (/^[A-Z0-9]$/.test(char)) {
					setCode((prev) => (prev + event.key.toUpperCase()).slice(-8));
				}
				if (char === 'Enter' && code.length === 8) {
					await verifyExit(code, isDown);
				}
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [code, openModal, isDown, verifyExit, activeCamera]);

	return (
		<>
			<Button
				size="medium"
				variant="text"
				component={Link}
				to="/"
				style={{ position: 'absolute', zIndex: 1400, left: '.5rem', top: '.5rem' }}
			>
				<ArrowBackIcon />
				<Typography sx={{ marginLeft: '0.5rem' }}>{t(`access:scan_exit`)}</Typography>
			</Button>
			{!isDown && scanQueue && scanQueue.length > 0 && (
				<Tooltip title={isSyncing ? 'Sincronizando...' : 'Sincronizar'}>
					<span>
						<Button
							variant="contained"
							onClick={syncStoredScan}
							color="primary"
							sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 50 }}
						>
							<LoopIcon
								sx={{
									animation: isSyncing ? `${spin} 1s linear infinite` : 'none',
									marginRight: '0.5rem',
								}}
							/>
							{t('access:sync')}
						</Button>
					</span>
				</Tooltip>
			)}

			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 20,
					backgroundColor: flash || 'none',
					opacity: flash === null ? 0 : 0.8,
					pointerEvents: 'none',
					transition: 'opacity 0.4s ease-in-out',
				}}
			></div>

			{activeCamera ? (
				<QRReader
					onScan={verifyExit}
					setOpenModal={setOpenModal}
					modalName={AccessModals.ManuallyEntry}
					routeName="scan_entry"
				/>
			) : (
				<div
					style={{
						padding: 0,
						margin: 0,
						width: '100vw',
						height: '100vh',
						overflow: 'hidden',
						backgroundColor: 'black',
						color: 'white',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{!openModal && (
						<>
							<Box
								// component="form"
								// onSubmit={async (e) => {
								// 	e.preventDefault();
								// 	await verifyExit(debugCode, isDown);
								// }}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
									maxWidth: 400,
									margin: 'auto',
									color: 'white',
								}}
							>
								<p>Esperando lectura de código QR...</p>
								{/* <TextField
									autoFocus
									value={debugCode}
									onChange={(e) => setDebugCode(e.target.value.toUpperCase())}
									label="Código"
									fullWidth
									InputProps={{
										style: { color: 'white' },
									}}
									InputLabelProps={{
										style: { color: 'white' },
									}}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'white',
											},
											'&:hover fieldset': {
												borderColor: 'lightgray',
											},
											'&.Mui-focused fieldset': {
												borderColor: 'white',
											},
										},
									}}
								/>
								<Button type="submit" variant="contained" color="primary">
									Enviar
								</Button> */}
							</Box>
						</>
					)}
				</div>
			)}
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '5.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setOpenModal(AccessModals.ManuallyExit);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{t('access:manually_enter')}
			</Button>
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '1.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setActiveCamera((prev) => !prev);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{activeCamera ? 'Utilizar escáner' : 'Utilizar cámara'}
			</Button>
			{/* MODALS */}
			{openModal && modals[openModal]}
		</>
	);
}

export default ExitScanner;
