import { useNavigate } from 'react-router-dom';
import { Box, LinearProgress, Typography, Grid, Card, CardContent, Button } from '@mui/material';

type Props = {
	loading: boolean;
	data: PopulatedShorcut[];
	onEdit: (shorcut: PopulatedShorcut) => void;
	refetch: () => void;
};

export default function ShorcutsCardList({ loading, data, onEdit, refetch }: Props) {
	const navigate = useNavigate();

	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			<Grid container spacing={2}>
				{data.length === 0 && (
					<Grid item xs={12}>
						<Typography></Typography>
					</Grid>
				)}
				{[...data]
					.sort((a, b) => a.order - b.order)
					.map((el) => (
						<Grid item xs={12} sm={6} md={4} lg={3} key={el._id} sx={{ mt: 2, display: 'flex' }}>
							<Card
								onClick={() => navigate(el.link, { replace: true })}
								sx={{
									border: '1px solid',
									borderColor: 'grey.300',
									borderRadius: 2,
									overflow: 'hidden',
									cursor: 'pointer',
									transition: 'transform 0.2s',
									'&:hover': { transform: 'scale(1.02)' },
									flex: 1,
									...(el.backgroundColor && { backgroundColor: el.backgroundColor }),
								}}
							>
								<CardContent>
									<Typography variant="h6" gutterBottom>
										{el.title}
									</Typography>
									<Typography
										variant="body2"
										color="text.secondary"
										sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
									>
										{el.description}
									</Typography>
									{el.textBottom && el.textBottom.length > 0 && (
										<Button
											variant="contained"
											color="primary"
											sx={{ mt: 2 }}
											onClick={(e) => {
												e.stopPropagation();
												navigate(el.link, { replace: true });
											}}
										>
											{el.textBottom}
										</Button>
									)}
								</CardContent>
							</Card>
						</Grid>
					))}
			</Grid>
		</Box>
	);
}
